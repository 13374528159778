import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import icono_usuario from "../img/icono_usuario3.png"
import axios from "axios";
import { useMemo } from 'react';
import DataTable from './DataTableAsegurados';
import Swal from 'sweetalert2';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';
import './table.css'

const AseguradosList = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingCurrent, setLoadingCurrent] = useState(true);

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '13%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [aseguradosList, setAsegurados] = useState([]);
    const [aseguradosAdmin, setAseguradosAdmin] = useState([]);
    const [aseguradosAdminBajas, setAseguradosAdminBajas] = useState([]);
    const [aseguradosListBajas, setAseguradosBajas] = useState([]);

    const [msg, setMsg] = useState("");

    const {user} = useSelector((state) => state.auth);

    useEffect(() => {
        if (user && user.role === "admin") {
            getAsegurados();
            getBajasAsegurados();
        } else {        
            getAseguradosUser();
            getBajasAseguradosUser();
        }
    }, [user]); // Observamos cambios en 'user'

    useEffect(() => {
        if (user && user.role === "admin") {
          getAsegurados();
        }
    }, []);

    const [dataTable, setDataTable] = useState([]);
    const [dataTableBajas, setDataTableBajas] = useState([]);

    // Define una función de filtro personalizada para la columna "Nombre"
    function NombreColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por nombre..."
        />
      );
    }

    function EmpresaColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por empresa..."
        />
      );
    }

    function SucursalColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por sucursal..."
        />
      );
    }

    function PolizaColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por poliza..."
        />
      );
    }

    function ApellidoPaternoColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por apellido paterno..."
        />
      );
    }

    function ApellidoMaternoColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por apellido materno..."
        />
      );
    }

    function RfcColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por RFC..."
        />
      );
    }

    const columns = useMemo(
        () => {
          const baseColumns = [
            {
                Header: 'Poliza',
                accessor: 'poliza',
                Filter: PolizaColumnFilter,
                Cell: ({ row }) => (
                  <>
                    {user && user.role === "admin" ? (
                      <Link to={`/historial/${row.original.poliza}`} className='btn btn-outline-dark m-1'>
                        {row.original.poliza}
                      </Link>
                    ) : (
                      <span>{row.original.poliza}</span>
                    )}
                  </>
                ),
              },
              {
                Header: 'Empresa',
                accessor: 'empresa.nombre',
                Filter: EmpresaColumnFilter,
                Cell: ({ row }) => (
                  <>                  
                  {user && user.role === "admin" ? (
                    <div>
                      <Link to={`/empresas/registros/${row.original.empresa.uuid}`} className='btn btn-light m-1'>{row.original.empresa.nombre}</Link>
                    </div>
                  ) : (
                    <span>{row.original && row.original.empresa && row.original.empresa.nombre ? row.original.empresa.nombre : ''}</span>
                  )}                  
                  </>
                )
              },
              {
                Header: 'Sucursal',
                accessor: 'sucursal.nombre',
                Filter: SucursalColumnFilter,
              },
              {
                Header: 'Nombre',
                accessor: 'nombre',
                Filter: NombreColumnFilter
              },
              {
                Header: 'Apellido paterno',
                accessor: 'apellido_paterno',
                Filter: ApellidoPaternoColumnFilter
              },
              {
                Header: 'Apellido materno',
                accessor: 'apellido_materno',
                Filter: ApellidoMaternoColumnFilter
              },
              {
                Header: 'RFC',
                accessor: 'rfc',
                Filter: RfcColumnFilter
              },
              {
                Header: 'F. nacimiento',
                accessor: 'fecha_nacimiento',
              },
              {
                Header: 'Inicio vigencia',
                accessor: 'inicio_vigencia',
              },
              {
                Header: 'Fin vigencia',
                accessor: 'fin_vigencia',
              }
          ];
    
          if (user && user.role === "admin") {
            baseColumns.push({
              Header: 'Acciones',
              accessor: 'actions',
              Cell: ({ row }) => (
                <div>
                  <Link to={`/asegurados/edit/${row.original.uuid}`} className='btn btn-info m-1'>Editar</Link>
                  <button onClick={() => deleteAfiliado(row.original.uuid)} className='btn btn-secondary'>Borrar</button>
                </div>
              ),
            });
          }
    
          return baseColumns;
        },
        [user]
    );

    const columnBajas = useMemo(
      () => {
        const baseColumns = [
          {
              Header: 'Poliza',
              accessor: 'poliza',
              Filter: PolizaColumnFilter,
              Cell: ({ row }) => (
                <>                  
                  {user && user.role === "admin" ? (
                    <Link to={`/historial/${row.original.poliza}`} className='btn btn-outline-dark m-1'>
                      {row.original.poliza}
                    </Link>
                  ) : (
                    <span>{row.original.poliza}</span>
                  )}
                </>
              ),
            },
            {
              Header: 'Empresa',
              accessor: 'empresa.nombre',
              Filter: EmpresaColumnFilter,
              Cell: ({ row }) => (
                <>                  
                {user && user.role === "admin" ? (
                  <div>
                    <Link to={`/empresas/registros/${row.original.empresa.uuid}`} className='btn btn-light m-1'>{row.original.empresa.nombre}</Link>
                  </div>
                ) : (
                  <span>{row.original && row.original.empresa && row.original.empresa.nombre ? row.original.empresa.nombre : ''}</span>
                )}                  
                </>
              )
            },
            {
              Header: 'Sucursal',
              accessor: 'sucursale.nombre',
              Filter: SucursalColumnFilter
            },
            {
              Header: 'Nombre',
              accessor: 'nombre',
              Filter: NombreColumnFilter
            },
            {
              Header: 'Apellido paterno',
              accessor: 'apellido_paterno',
              Filter: ApellidoPaternoColumnFilter
            },
            {
              Header: 'Apellido materno',
              accessor: 'apellido_materno',
              Filter: ApellidoMaternoColumnFilter
            },
            {
              Header: 'RFC',
              accessor: 'rfc',
              Filter: RfcColumnFilter
            },
            {
              Header: 'F. nacimiento',
              accessor: 'fecha_nacimiento',
            },
            {
              Header: 'Inicio vigencia',
              accessor: 'inicio_vigencia',
            },
            {
              Header: 'Fin vigencia',
              accessor: 'fin_vigencia',
            }
        ];
  
        return baseColumns;
      },
      [user]
  );

    const getAsegurados = async () => {
        try {
            setLoadingCurrent(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/asegurados`);          
            setDataTable(response.data)
            setAseguradosAdmin(response.data);
            setLoadingCurrent(false); // Indicar que se están cargando los datos
        } catch (error) {
            console.log(error.message);
        }
    }

    const getBajasAsegurados = async () => {
      try {
          setLoading(true); // Indicar que se están cargando los datos
          const response = await axios.get(`https://api.seguroll-asistencias.com/baja-asegurados`);          
          setDataTableBajas(response.data)
          setAseguradosAdminBajas(response.data);
          setLoading(false); // Indicar que se están cargando los datos
      } catch (error) {
          console.log(error.message);
      }
    }

    const getAseguradosUser = async() => {                
        try {
            setLoadingCurrent(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/asegurados`);
            const aseguradosUser = response.data.data
                        
            let data = [];
            const dataClean = aseguradosUser.map((asegurado) => asegurado.sucursales ? asegurado.sucursales : asegurado.asegurados);         
                              
            data = [].concat(...dataClean);            

            const asegurados = data.map((asegurado) => asegurado)

            let dataAsegurado = [];
            asegurados.forEach(asegurado => {                          
              
              if (asegurado.asegurados && asegurado.asegurados.length > 0) { // Si esto no jala, quita el length y busca otra forma

                dataAsegurado.push(...asegurado.asegurados);

              } else{                
                //dataAsegurado.push(asegurado.asegurados);
                dataAsegurado.push(asegurado);
                //dataAsegurado = [].concat(...asegurado);

              }

            });
            
            setDataTable(dataAsegurado)
            setAsegurados(dataAsegurado);
            setLoadingCurrent(false); // Indicar que se están cargando los datos          
        } catch (error) {
            //console.log(error.message);
        }
    }

    const getBajasAseguradosUser = async() => {                
      try {
          setLoading(true); // Indicar que se están cargando los datos
          const response = await axios.get(`https://api.seguroll-asistencias.com/baja-asegurados`);
          const aseguradosUser = response.data.data

          //console.log(JSON.stringify(aseguradosUser, null, 4));

          let data = [];
          const dataClean = aseguradosUser.map((asegurado) => asegurado.sucursales ? asegurado.sucursales : asegurado.asegurados_bajas);
                  
          data = [].concat(...dataClean);
          
          let asegurados = [];
          data.forEach(item => {

            if(item.asegurados_bajas && item.asegurados_bajas.length > 0){
              
              for (let index = 0; index < item.asegurados_bajas.length; index++) {
                const element = item.asegurados_bajas[index];
                asegurados.push(element);
              }

            }else if (!item.asegurados_bajas) {
              asegurados.push(item);
            }

          })
          
          setDataTableBajas(asegurados)
          setAseguradosBajas(asegurados);
          setLoading(false); // Indicar que se están cargando los datos          
      } catch (error) {
          //console.log(error.message);
      }
  }

    // Eliminamos a los usuarios
    const deleteAfiliado = async (aseguradoId) => {

      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, estoy seguro',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => { 
        
        if (result.isConfirmed) {
            try {

                await axios.delete(`https://api.seguroll-asistencias.com/asegurados/${aseguradoId}`);            
                if (user && user.role === "admin") {
                    setDataTable((dataTable) => dataTable.filter((item) => {                
                        return item.uuid !== aseguradoId;
                    }));
                    getAsegurados();
                } else {
                    setDataTable((dataTable) => dataTable.filter((item) => {                
                        return item.uuid !== aseguradoId;
                    }));
                    getAseguradosUser();
                }

            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }        

      });        

    }

    const [empresasFiles, setEmpresasFiles] = useState([]);
    const [loadingFile, setLoadingFile] = useState(false);
    const [empresaSelectFiles, setEmpresaSelectFiles] = useState("");
    const [sucursalSelectFiles, setSucursalSelectFiles] = useState("");
    const [empresaNameFile, setEmpresaNameFiles] = useState("");
    const [sucursales, setSucursales] = useState([]);

    useEffect(() => {
      if (user && user.role === "admin") {          
          //getDataGrafica();
      } else {
          getEmpresasUser();// si el usuario no es admin, cargamos otras empresas
      }
    }, [user]); // Observamos cambios en 'user'

    const getEmpresasUser = async () => {
      try {
          let response;
          if(user){
              response = await axios.get(`https://api.seguroll-asistencias.com/empresasUser/${user.uuid}`);
              let data = []
              const userEmpresas = response.data.usuarios_empresas;
              
              userEmpresas.forEach(empresa => {
                  data.push(empresa.empresa)
              });                
              setEmpresasFiles(data);
          }
      } catch (error) {
          console.log(error.message);
      }
    }

    useEffect(() => {        
      const getSucursalesByEmpresa = async () => {
          let response;
          try {
              if(empresaSelectFiles !== ''){
                  response = await axios.get(`https://api.seguroll-asistencias.com/sucursalesUser/${empresaSelectFiles}`);                    
                  setSucursales(response.data);

                  const empresaEncontrada = empresasFiles.find(item => item.id == empresaSelectFiles);
                  if (empresaEncontrada) {
                      setEmpresaNameFiles(empresaEncontrada.nombre);
                  } else {
                      setEmpresaNameFiles("Sucursal...");
                  }

              }
          } catch (error) {
              if(error.response){
                  setMsg(error.response.data.msg);
              }
          }
      }
      getSucursalesByEmpresa();
    }, [empresaSelectFiles])    

    const spinnerStyle = css`
        display: block;
        margin: 0 auto;
    `;

    const descargarExcel = async(e) => {
      e.preventDefault();
      
      let response;
      try {
          setLoadingFile(true);
          response = await axios.get(`https://api.seguroll-asistencias.com/dowloadFileUser`, {
              params: {
                empresaId: empresaSelectFiles,
                sucursalId: sucursalSelectFiles,
              },
              responseType: 'arraybuffer',
          });
          
          // Crear un blob a partir de los datos binarios recibidos en la respuesta
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Crear un objeto URL para el blob
          const url = window.URL.createObjectURL(blob);

          // Crear un enlace de descarga y hacer clic en él para iniciar la descarga del archivo Excel
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'data.xlsx');
          document.body.appendChild(link);
          link.click();

          // Liberar el objeto URL
          window.URL.revokeObjectURL(url);
          setLoadingFile(false);
      } catch (error) {
          if(error.response){
              setMsg(error.response.data.msg);
          }
      }
    }

    return (

        <div className='container-fluid'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Directorio de Asegurados</h1>
                        <h5 className='subtitle'>Explora la Lista Completa de Asegurados</h5>
                        {/*user && user.role === "admin" && <Link to="/asegurados/add" className='btn btn-light text-primary'>AÑADIR ASEGURADO</Link>*/}
                    </div>
                    <div className='col text-end'>
                        <img src={icono_usuario} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className="alert alert-custom-navy" role="alert">
                <strong>FILTRO DE BÚSQUEDA</strong>
                <p>Para filtrar la tabla, solo necesitas ingresar algún valor, como una fecha, nombre, número de póliza, u otro dato relevante.</p>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>
                <h4 className=' subtitle mt-2 mb-3'>Localizador de Asegurados Activos</h4>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loadingCurrent ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columns} data={dataTable}/>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="alert alert-custom-brown" role="alert">
                <strong>FILTRO DE BÚSQUEDA</strong>
                <p>Para filtrar la tabla, solo necesitas ingresar algún valor, como una fecha, nombre, número de póliza, u otro dato relevante.</p>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>
                <h4 className=' subtitle mt-2 mb-3'>Localizador de Asegurados inactivos</h4>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                      <>
                        {dataTableBajas.length === 0 ? (
                          <p>Sin información disponible.</p>
                        ) : (
                          <>
                            {dataTableBajas.some((element) => element === undefined) ? (
                              <p>Cargando datos...</p>
                            ) : (
                              <DataTable columns={columnBajas} data={dataTableBajas} />
                            )}
                          </>
                        )}
                      </>
                    )}
                </div>
            </div>

            {user && user.role === "admin" ? (
              <div className='card mt-5 shadow p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                      <div className='col'>
                          <h4 className='title'>Carga Extraordinaria</h4>
                          <p className='subtitle'>Registra un asegurado nuevo no incluido en la carga mensual</p>
                          {user && user.role === "admin" && <Link to="/asegurados/add" className='btn btn-dark'>AÑADIR ASEGURADO</Link>}
                      </div>
                      <div className='col text-end'>
                          <img src={icono_usuario} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                      </div>
                    </div>
                </div>
              </div>
            ) : (
              <></>
            )}


            {user && user.role !== "admin" ? (
                <div className="card mb-5">

                    <Row className="d-flex justify-content-center align-items-center mt-3 mb-3">
                        <Col md={8} lg={11} xs={12}>
                            <div className="alert alert-custom-purple" role="alert">
                                <h5><strong>DESCARGA DE LISTA DE PÓLIZAS</strong></h5>
                                <ol>
                                    <li>Elige la empresa de la que deseas descargar el archivo de Excel y, si lo deseas, puedes también indicar una sucursal específica.</li>                                    
                                </ol>
                                {/*<p><strong>Nota:</strong> Solo podrás descargar los archivos de las empresas a las que tienes acceso.</p>*/}
                            </div>

                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        {/*<p className=" mb-2 pt-0"><strong>Por favor, selecciona el archivo, la empresa y, en caso de tener una, la sucursal para cargar tu archivo.</strong></p>*/}
                                        <div className="mb-3">
                                            <form onSubmit={descargarExcel}>
                                                {/*msg &&
                                                    <div className="alert alert-success" role="alert">
                                                        {msg}
                                                    </div>
                                                    */}
                                                {loadingFile ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Generando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {msg &&
                                                            <div className="alert alert-success" role="alert">
                                                                <p>Archivo generado con éxito</p>
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                                
                                                <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                    <Form.Select
                                                        value={empresaSelectFiles}
                                                        onChange={(e) => setEmpresaSelectFiles(e.target.value)}
                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                        style={{ height: "100%" }}
                                                    >
                                                        <option value="">Empresa...</option>
                                                        {empresasFiles.map((empresa) => (
                                                            <option key={empresa.id} value={empresa.id}>{empresa.nombre}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                    <Form.Select
                                                        value={sucursalSelectFiles}
                                                        onChange={(e) => setSucursalSelectFiles(e.target.value)}
                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                        style={{ height: "100%" }}
                                                    >
                                                        <option value="">
                                                            {(!sucursales || sucursales.length === 0) ? (
                                                                empresaNameFile || "Sucursal..."
                                                            ) : "Sucursal..."}
                                                        </option>
                                                        {sucursales.map((sucursal) => (
                                                            <option key={sucursal.sucursale.id} value={sucursal.sucursale.id}>{sucursal.sucursale.nombre}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Descargar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                    </Row>
                </div>
            ) : (
                <></>
            )}

        </div>

    )

}

export default AseguradosList