import React, {useEffect} from "react";
import Layout from "./Layout";
import FromEditAsegurados from "../components/FormEditAsegurado"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMe } from "../features/authSlice";

const EditAsegurado = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isError, user} = useSelector((state => state.auth));

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if(isError){
            navigate("/")
        }
        if(user && user.role !== 'admin'){
            navigate("/dashboard")
        }
    }, [isError, user, navigate]);

    return (
        <Layout>
            <FromEditAsegurados/>
        </Layout>
      )

}

export default EditAsegurado