import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import icono_usuario from "../img/icono_usuario3.png"
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import DataTable from './DataTables';

const HistorialGeneralPolizas = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [loading, setLoading] = useState(true);

    const [aseguradosHistorial, setAseguradosHistorial] = useState([]);
    const [msg, setMsg] = useState("");
    const {user} = useSelector((state) => state.auth);
    const {empresaId} = useParams();

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '13%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []); 

    const [dataTable, setDataTable] = useState([]);

    const columns = useMemo(
        () => {
          const baseColumns = [
            {
                Header: 'Poliza',
                accessor: 'poliza'
              },
              {
                Header: 'Empresa',
                accessor: 'empresa.nombre',
              },
              {
                Header: 'Sucursal',
                accessor: 'sucursale.nombre',
              },
              {
                Header: 'Nombre',
                accessor: 'nombre',
              },
              {
                Header: 'Apellido paterno',
                accessor: 'apellido_paterno',
              },
              {
                Header: 'Apellido materno',
                accessor: 'apellido_materno',
              },
              {
                Header: 'Inicio vigencia',
                accessor: 'inicio_vigencia',
              },
              {
                Header: 'Fin vigencia',
                accessor: 'fin_vigencia',
              },
              {
                Header: 'Accion',
                accessor: 'accion',
              },
              {
                Header: 'Creado',
                accessor: 'createdAt',
              },
              {
                Header: 'Actualizado',
                accessor: 'updatedAt',
              }
          ];
        
          return baseColumns;
        },
        [user]
    );

    useEffect(() => {        
        if (user && user.role === "admin") {
            getAseguradoHistorialGeneral();
        }
    }, [user, empresaId]);


    const getAseguradoHistorialGeneral = async () => {        
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/historialGeneral/${empresaId}`);
            
            setDataTable(response.data)
            setAseguradosHistorial(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {            
            console.log(error.message);
        }
    }

    return (

        <div className='container-fluid'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Historial</h1>
                        <h5 className='subtitle'>Historial completo de la empresa</h5>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_usuario} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>
                <h4 className=' subtitle mt-2 mb-3'>Registros encontrados</h4>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columns} data={dataTable} />
                            )}
                        </>
                    )}
                </div>
            </div>

        </div>
    )

}

export default HistorialGeneralPolizas