import React, {useEffect, useState} from 'react'
import { useTable, useRowSelect } from 'react-table';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_edi_user from '../img/icono_edi_user.png'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const FromEditAsegurados = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);        
    }, []);

    const [poliza, setPoliza] = useState("");
    const [rfc, setRfc] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido_paterno, setApellidoPaterno] = useState("");
    const [apellido_materno, setApellidoMaterno] = useState("");
    const [claveUno, setClaveUno] = useState("0");
    const [claveDos, setClaveDos] = useState("0");
    const [inicioVigencia, setInicioVigencia] = useState("");
    const [finVigencia, setFinVigencia] = useState("");
    const [claveTres, setClaveTres] = useState("NA");
    const [claveCuatro, setClaveCuatro] = useState("CTMS");
    const [claveCinco, setClaveCinco] = useState("SCTM15");
    const [selectEmpresa, setSelectEmpresa] = useState("");
    const [selectSucursal, setSelectSucursal] = useState("");
    const [fechaNacimiento, setFechaNacimiento] = useState("");
    const [selectSexo, setSelectSexo] = useState("");
    const [claveSeis, setClaveSeis] = useState("NA");
    const [claveSiete, setClaveSiete] = useState("0");

    const [empresas, setEmpresas] = useState([]);
    const [sucursales, setSucursales] = useState([]);

    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        const getAseguradoById = async () => {
            try {
                const response = await axios.get(`https://api.seguroll-asistencias.com/asegurados/${id}`);
                
                setPoliza(response.data.poliza);
                setRfc(response.data.rfc);
                setNombre(response.data.nombre);
                setApellidoPaterno(response.data.apellido_paterno);
                setApellidoMaterno(response.data.apellido_materno);
                setClaveUno(response.data.unknow_uno);
                setClaveDos(response.data.unknow_dos);
                setInicioVigencia(response.data.inicio_vigencia);
                setFinVigencia(response.data.fin_vigencia);
                setClaveTres(response.data.unknow_tres);
                setClaveCuatro(response.data.unknow_cuatro);
                setClaveCinco(response.data.unknow_cinco);
                setSelectEmpresa(response.data.empresa_asegurados);

                if (response.data.sucursal && response.data.sucursal.id) {
                    setSelectSucursal(response.data.sucursal.id);
                }                

                setFechaNacimiento(response.data.fecha_nacimiento);
                setSelectSexo(response.data.sexo);
                setClaveSeis(response.data.unknow_seis);
                setClaveSiete(response.data.unknow_siete);
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getAseguradoById();
        getEmpresas();
    }, [id]);

    const [empresaName, setEmpresaName] = useState("");
    useEffect(() => {

        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(selectEmpresa !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursales/${selectEmpresa}`);
                    setSucursales(response.data);

                    const empresaEncontrada = empresas.find(item => item.id == selectEmpresa);
                    if (empresaEncontrada) {
                        setEmpresaName(empresaEncontrada.nombre);
                    } else {
                        setEmpresaName("Selecciona una sucursal...");
                    }
                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [selectEmpresa])
    
    const getEmpresas = async () => {
        try {
        const response = await axios.get('https://api.seguroll-asistencias.com/empresas');        
        setEmpresas(response.data); 
        } catch (error) {
            console.log(error.message)
        }        
    }

    const updateAsegurado = async(e) => {
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            // Si el usuario hizo clic en el botón "Sí, estoy seguro"
            if (result.isConfirmed) {
        
                try {
                    await axios.patch(`https://api.seguroll-asistencias.com/asegurados/${id}`, {
                        poliza: poliza,
                        polizaConfirm: poliza,
                        rfc: rfc,
                        nombre: nombre,
                        apellido_paterno: apellido_paterno,
                        apellido_materno: apellido_materno,
                        unknow_uno: claveUno,
                        unknow_dos: claveDos,
                        inicio_vigencia: inicioVigencia,
                        fin_vigencia: finVigencia,
                        unknow_tres: claveTres,
                        unknow_cuatro: claveCuatro,
                        unknow_cinco: claveCinco,
                        empresa_asegurados: selectEmpresa,
                        sucursalId: selectSucursal,
                        fecha_nacimiento: fechaNacimiento,
                        sexo: selectSexo,
                        unknow_seis: claveSeis,
                        unknow_siete: claveSiete,
                        empresaId: selectEmpresa
                    });
                    Swal.fire('Éxito', 'La acción se realizó correctamente', 'success');
                    //navigate("/asegurados")
                } catch (error) {
                    Swal.fire('Error', 'La acción falló', 'error');
                    if(error.response){
                        setMsg(error.response.data.msg)
                    }
                }                
            }
        });
        
    }

    return (

        <div className='mb-5'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Asegurados</h1>
                        <h2 className='subtitle'>Editar asegurado</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_edi_user} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>

            <Container style={{ maxHeight: "50%"}}>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={8} lg={10} xs={12}>
                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-5 pt-0">*El campo poliza es obligatorio</p>
                                        <div className="mb-3">
                                            <Form onSubmit={updateAsegurado}>
                                                {msg &&
                                                    <div className="alert alert-danger" role="alert">
                                                        {msg}
                                                    </div>
                                                }
                                                <Form.Group className="mb-3" controlId="formBasicPoliza">
                                                    <Form.Label className="text-center">
                                                    No. Poliza
                                                    </Form.Label>
                                                    <Form.Control value={poliza} onChange={(e) => setPoliza(e.target.value)} type="text" placeholder="Escribe tu numero de poliza" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                    RFC
                                                    </Form.Label>
                                                    <Form.Control value={rfc} onChange={(e) => setRfc(e.target.value)} type="text" placeholder="Escribe tu rfc" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                    Nombre
                                                    </Form.Label>
                                                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Escribe tu nombre" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoP">
                                                    <Form.Label className="text-center">
                                                    Apellido paterno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_paterno} onChange={(e) => setApellidoPaterno(e.target.value)} type="text" placeholder="Escribe tu apellido paterno" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoM">
                                                    <Form.Label className="text-center">
                                                    Apellido materno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_materno} onChange={(e) => setApellidoMaterno(e.target.value)} type="text" placeholder="Escribe tu apellido materno" />
                                                </Form.Group>                                            

                                                {/*
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave uno
                                                        </Form.Label>
                                                        <Form.Control value={claveUno} onChange={(e) => setClaveUno(e.target.value)} type="text" placeholder="Clave uno" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave dos
                                                        </Form.Label>
                                                        <Form.Control value={claveDos} onChange={(e) => setClaveDos(e.target.value)} type="text" placeholder="Clave dos" />
                                                    </Form.Group>
                                                */}

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                    Inicio de la vigencia
                                                    </Form.Label>
                                                    <Form.Control value={inicioVigencia} onChange={(e) => setInicioVigencia(e.target.value)} type="date" placeholder="0000-00-00" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                    Fin de la vigencia
                                                    </Form.Label>
                                                    <Form.Control value={finVigencia} onChange={(e) => setFinVigencia(e.target.value)} type="date" placeholder="0000-00-00" />
                                                </Form.Group>

                                                {/* 
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave tres
                                                        </Form.Label>
                                                        <Form.Control value={claveTres} onChange={(e) => setClaveTres(e.target.value)} type="text" placeholder="Clave tres" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave cuatro
                                                        </Form.Label>
                                                        <Form.Control value={claveCuatro} onChange={(e) => setClaveCuatro(e.target.value)} type="text" placeholder="Clave cuatro" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave cinco
                                                        </Form.Label>
                                                        <Form.Control value={claveCinco} onChange={(e) => setClaveCinco(e.target.value)} type="text" placeholder="Clave cinco" />
                                                    </Form.Group>
                                                */}

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">Selecciona la empresa</Form.Label>
                                                    <Form.Control
                                                    as="select"
                                                    value={selectEmpresa} 
                                                    onChange={(e) => setSelectEmpresa(e.target.value)}
                                                    >
                                                    <option value="">Selecciona una opción</option>
                                                    {empresas.map(empresa => (
                                                        <option key={empresa.id} value={empresa.id}>
                                                            {empresa.nombre}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">Selecciona la sucursal</Form.Label>
                                                    <Form.Control
                                                    as="select"
                                                    value={selectSucursal}
                                                    onChange={(e) => setSelectSucursal(e.target.value)}
                                                    >

                                                    <option value="">
                                                        {(!sucursales || sucursales.length === 0) ? (
                                                            empresaName || "Selecciona una sucursal..."
                                                        ) : "Seleccione una opción..."}
                                                    </option>
                                                    {sucursales.map(sucursal => (
                                                        <option key={sucursal.nombre} value={sucursal.id}>
                                                            {sucursal.nombre}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                    Fecha de nacimiento
                                                    </Form.Label>
                                                    <Form.Control value={fechaNacimiento} onChange={(e) => setFechaNacimiento(e.target.value)} type="date" placeholder="0000-00-00" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">Selecciona el sexo</Form.Label>
                                                    <Form.Control
                                                    as="select"
                                                    value={selectSexo}
                                                    onChange={(e) => setSelectSexo(e.target.value)}
                                                    >
                                                    <option value="">Selecciona una opción</option>
                                                    <option value="M">Mujer</option>
                                                    <option value="H">Hombre</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                
                                                {/* 
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave seis
                                                        </Form.Label>
                                                        <Form.Control value={claveSeis} onChange={(e) => setClaveSeis(e.target.value)} type="text" placeholder="Clave seis" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="text-center">
                                                        Clave siete
                                                        </Form.Label>
                                                        <Form.Control value={claveSiete} onChange={(e) => setClaveSiete(e.target.value)} type="text" placeholder="Clave siete" />
                                                    </Form.Group>
                                                */}

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Actualizar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>

        </div>

    )

}

export default FromEditAsegurados