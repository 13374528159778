import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import user_icon from '../img/user_icon.png'
import axios from 'axios'
import Swal from 'sweetalert2'

const Userlist = () => {

  // Declarar imageStyle aquí, en el alcance del componente Navbar
  const [imageStyle, setImageStyle] = useState({});

  const applyImageStyles = () => {
    const smallScreen = window.matchMedia('(max-width: 576px)').matches;
    const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
    const largeScreen = window.matchMedia('(min-width: 769px)').matches;

    if (smallScreen) {
      setImageStyle({ maxWidth: '75%' });
    } else if (mediumScreen) {
      setImageStyle({ maxWidth: '35%' });
    } else if (largeScreen) {
      setImageStyle({ maxWidth: '20%' });
    }
  };

  useEffect(() => {
    applyImageStyles();
    window.addEventListener('resize', applyImageStyles);
    return () => window.removeEventListener('resize', applyImageStyles);
  }, []);

  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    getUsuarios();
  }, []);

  const getUsuarios = async () => {
    try {
      const response = await axios.get('https://api.seguroll-asistencias.com/users');
      setUsuarios(response.data); 
    } catch (error) {
      console.log(error.message)
    }    
  }

  const deleteUsuarios = async (userId) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => { 

      if (result.isConfirmed) {
        await axios.delete(`https://api.seguroll-asistencias.com/users/${userId}`);
        getUsuarios();
      }

    });   

  }

  return (
    <div>

        <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <h1 className='title'>Usuarios</h1>
                <h3 className='subtitle'>Lista de usuarios</h3>
                <Link to="/users/add" className='btn btn-light text-primary'>Nuevo usuario</Link>
              </div>
              <div className='col text-end'>
                  <img src={user_icon} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
              </div>
            </div>            
          </div>
        </div>

        <div className='card mt-0 shadow p-3 mb-5 bg-body rounded'>
          <div className='table-responsive' style={{ maxHeight: "300px" }}>
            <table className="table table-striped align-middle table-hover table-sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Poliza</th>
                  <th>Nombre</th>
                  <th>Apellido P.</th>
                  <th>Apellido M.</th>
                  <th>Email</th>
                  <th>Acciónes</th>
                </tr>
              </thead>
              <tbody>
                {usuarios.map((usuario, index) => (
                  <tr key={usuario.uuid}>
                    <td>{index + 1}</td>
                    <td>{usuario.poliza}</td>
                    <td>{usuario.nombre}</td>
                    <td>{usuario.apellido_paterno}</td>
                    <td>{usuario.apellido_materno}</td>
                    <td>{usuario.email}</td>
                    <td>
                      <Link to={`/users/edit/${usuario.uuid}`} className='btn btn-info m-1'>Editar</Link>
                      <button onClick={() => deleteUsuarios(usuario.uuid)} className='btn btn-secondary'>Borrar</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

    </div>
  )
}

export default Userlist