import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginUser, reset } from '../features/authSlice';
import bannerlogin from '../img/bannerlogin_v2.png';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import tutorial from '../img/tutorial.mp4'

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();    
    const { user, isError, isSuccess, isLoading, message } = useSelector(
        (state) => state.auth
    );

    const autoLogin = () => {
        const storedEmail = localStorage.getItem('email');
        const storedPassword = localStorage.getItem('password');
    
        if (storedEmail && storedPassword) {
            // Intenta iniciar sesión automáticamente con las credenciales almacenadas
            dispatch(LoginUser({ email: storedEmail, password: storedPassword }));
        }
    };

    useEffect(() => {
        // Intenta iniciar sesión automáticamente al cargar el componente
        autoLogin();
    }, []);

    const saveCredentialsToLocalStorage = () => {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
    };    

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if(user || isSuccess){
            navigate("/dashboard");
        }
        dispatch(reset());
    }, [user, isSuccess, dispatch, navigate]);

    const Auth = (e) => {
        e.preventDefault();
        dispatch(LoginUser({email, password}));
        saveCredentialsToLocalStorage();
    }

    return (
        <div>
        <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>            
                <Card className="shadow">

                    <div className="position-relative overflow-hidden px-0">

                        <div
                            className="position-relative overflow-hidden px-0"
                            style={{
                            backgroundColor: "#000000", // Fondo negro
                            opacity: 0.8, // Opacidad del 80%
                            maxHeight: "200px", // Ajusta la altura máxima deseada
                            width: "100%", // La imagen ocupará el 100% del ancho disponible
                            objectFit: "cover", // Ajusta el tipo de ajuste del contenido
                            }}
                        >

                            <img
                                src={bannerlogin}
                                alt="Imagen de fondo"
                                className="card-img-top w-100 h-100"
                                style={{
                                    filter: "blur(0px)", // Ajusta el valor del desenfoque
                                    opacity: 1, // Opacidad de fondo
                                    maxHeight: "200px", // Ajusta la altura máxima deseada
                                    width: "100%", // La imagen ocupará el 100% del ancho disponible
                                    objectFit: "cover", // Ajusta el tipo de ajuste del contenido
                                }}
                            />                            

                        </div>

                    </div>                    

                    <Card.Body>
                        <div className="mb-3 mt-md-0">                  

                            <div className="mb-3 mt-2">
                                <Form onSubmit={Auth} >
                                    
                                        {isError &&
                                        <div className="alert alert-danger" role="alert">
                                            {message}
                                        </div>
                                        }                                    
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="text-center">
                                        Correo Electronico
                                        </Form.Label>
                                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Escribe tu correo" />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                    >
                                        
                                    </Form.Group>

                                    <div className="alert alert-primary mt-3 text-center" role="alert">
                                        <div>
                                            <p>Al acceder al sistema, aceptas estar de acuerdo con nuestros términos y condiciones, y te obligas a no divulgar la información con terceros. Conoce nuestro aviso de privacidad <a href='https://www.seguroll.mx/aviso-de-privacidad'>aqui</a></p>
                                        </div>
                                    </div>

                                    <div className="d-grid btn-lg">
                                        <Button type='submit' variant="primary"
                                        className='btn-dark'>
                                        {isLoading ? "Cargando..." : "Login"}
                                        </Button>
                                    </div>
                                </Form>                                

                                <div className="mt-3 text-center ">
                                    <div>
                                        <a className='text-secondary' href="#" onClick={handleShow}>
                                            Video Tutorial
                                        </a>
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>video Tutorial</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='embed-responsive embed-responsive-16by9 d-flex justify-content-center align.items-center'>
                                                    <iframe src={tutorial} className='embed-responsive-item' frameBorder="0" allowFullScreen></iframe>                                                    
                                                </div>                                                
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant='secondary' onClick={handleClose}>
                                                    Cerrar
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                    {/*<p className="mb-0  text-center">
                                        ¿No tienes cuenta?{" "}
                                        <a href="{''}" className="text-dark fw-bold">
                                        Registrate
                                        </a>
                                    </p>*/}
                                </div>
                            </div>
                        </div>
                    </Card.Body>                    

                </Card>

                <div className="p-5 text-center mt-3" style={{
                backgroundColor: '#FFFFFF',
                height: '400px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative', // Añadir posición relativa para que la superposición funcione correctamente
                }}>            
                    <div className="mask" style={{
                        backgroundColor: '#FFFFFF', // Ajusta el valor alfa (0.6) para la opacidad
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div className="text-primary">
                            <h1 className="mb-3">SEGUROLL ASISTENCIAS</h1>
                            <h5 className="mb-3 text-black fw-normal">Nuestro equipo de especialistas con más de 25 años de experiencia en el ramo, lo orientaran acerca de nuestro amplio portafolio de productos y servicios. Permita que nuestros agentes encuentren la mejor solución para usted y su negocio.</h5>
                            <a className="btn btn-outline-primary btn-lg" href="tel:5530002939" role="button">Contactar con un agente</a>
                            <br/>
                            {/*<a className="btn btn-link btn-lg" style={{ color: '#FFFFFF' }} href="https://appseguroll.masservicios.com.mx/" role="button">¿Ya cuentas con una poliza? da clic aqui</a>*/}
                        </div>
                    </div>
                </div>

            </Col>            
            </Row>
        </Container>
        </div>
    );
}

export default Login