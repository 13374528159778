import { useState, useCallback } from 'react';

export const useCameraPermission = () => {
    const [hasPermission, setHasPermission] = useState(false);

    const requestPermission = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            // Si se obtiene el permiso, establece hasPermission en true
            setHasPermission(true);
            // Detén la transmisión de video
            stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
            console.error('Error al solicitar permiso de cámara:', error);
        }
    }, []);

    return {
        hasPermission,
        requestPermission,
    };
};