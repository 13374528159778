import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useFilters, usePagination, useGlobalFilter, useSortBy, useAsyncDebounce  } from 'react-table';
import { ColumnFilter } from "./ColumnFilter";

const DataTable = ({ columns, data }) => {

  const pageSize = 50; // Define el tamaño de la página según tus necesidades

  const totalRecords = data.length;
  const totalPages = Math.ceil(totalRecords / pageSize);

  const [tableRendered, setTableRendered] = useState(false);

  useEffect(() => {    
    setTableRendered(true);
  }, [data]); // Se ejecutará cuando cambie 'data'
  
  const tableStyles = {
    table: 'table table-sm table-striped align-middle table-hover',
    thead: 'table-dark',
  };
  
  const useDebouncedGlobalFilter = (rows, { globalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const debouncedSetGlobalFilter = useAsyncDebounce((val) => {
      setValue(val || undefined);
    }, 200);
  
    useEffect(() => {
      debouncedSetGlobalFilter(globalFilter);
    }, [globalFilter, debouncedSetGlobalFilter]);
  
    return value;
  };  

  const defaultColumn = React.useMemo(() => {
    return {
        Filter: ColumnFilter,
    };
  }, []);
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter, // Add setGlobalFilter from useGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ); // Use the required hooks as an array

  const { pageIndex, globalFilter } = state; // Extract the global filter from state

  const globalFilterValue = useDebouncedGlobalFilter(data, {
    globalFilter: state.globalFilter,
  });

  return (
    <div className='table-responsive'>
      <div className="d-flex justify-content-end">
        {/* Add the search bar */}
        <input
          type="text"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          placeholder="Filtros..."
          className='mb-2 form-control'
        />
      </div>

      {data.length === 0 ? ( // Verifica si el arreglo 'data' está vacío      
        <p>Cargando datos...</p>
      ) : (              

        <table {...getTableProps()} className={tableStyles.table}>
          <thead className={tableStyles.thead}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ padding: '5px' }}
                  >
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    {/* Agrega el componente de filtro si está presente */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td className='text-center' {...cell.getCellProps()} style={{ padding: '5px' }}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        
      )}

      <div className="d-flex justify-content-center mt-2">
        <p>Total de registros: {totalRecords}</p>
        <p className="mx-3">Total de páginas: {totalPages}</p>
      </div>

      <div className="d-flex justify-content-center mb-5">
        <button className="btn btn-primary mx-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
          Anterior
        </button>
        <span style={{ margin: '0 10px' }}>Pagina {pageIndex + 1}</span>
        <button className="btn btn-primary mx-1" onClick={() => nextPage()} disabled={!canNextPage}>
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default DataTable;