import React, {useEffect, useState} from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_archivos from '../img/icono_archivos.png'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';
import './table.css'

const CargarArchivo = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [msg, setMsg] = useState("");
    const [msgError, setMsgError] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);

    const [loadingArchivo, setLoadingArchivo] = useState(false);

    const navigate = useNavigate();

    const [selectEmpresa, setSelectEmpresa] = useState("");
    const [selectSucursal, setSelectSucursal] = useState("");
    const [empresas, setEmpresas] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [selectedReporte, setSelectedReporte] = useState(null);
    const [loadingReporte, setLoadingReporte] = useState(false);
    const [msgReporte, setMsgReporte] = useState("");
    const [msgErrorReporte, setMsgErrorReporte] = useState("");
    const [empresasReportes, setEmpresasReportes] = useState([]);
    const [selectEmpresaReportes, setSelectEmpresaReportes] = useState("");
    const [tituloReporte, setTituloReporte] = useState("");

    /** Reportes globales */
    const [msgErrorReporteGlobales, setMsgErrorReporteGlobales] = useState("");
    const [msgReporteGlobales, setMsgReporteGlobales] = useState("");
    const [loadingReporteGlobal, setLoadingReporteGlobal] = useState(false);
    const [tituloReporteGlobal, setTituloReporteGlobal] = useState("");
    const [selectedReporteGlobal, setSelectedReporteGlobal] = useState(null);
    /**
     * 
     * arreglos necesarios para la descarga de archivos
     * 
     */

    const [selectEmpresaFiles, setSelectEmpresaFiles] = useState("");
    const [selectSucursalFiles, setSelectSucursalFiles] = useState("");
    const [empresasFiles, setEmpresasFiles] = useState([]);
    const [sucursalesFiles, setSucursalesFiles] = useState([]);

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    useEffect(() => {
        getEmpresas();
    }, []);

    const getEmpresas = async () => {
        try {
            const response = await axios.get('https://api.seguroll-asistencias.com/empresas');        
            setEmpresas(response.data);
            setEmpresasFiles(response.data);
            setEmpresasReportes(response.data);
        } catch (error) {
            console.log(error.message)
        }        
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(selectEmpresa !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursales/${selectEmpresa}`);
                    setSucursales(response.data);                    
                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [selectEmpresa])

    useEffect(() => {
        const getSucursalesByEmpresaFiles = async () => {
            let response;
            try {
                if(selectEmpresaFiles !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursales/${selectEmpresaFiles}`);
                    setSucursalesFiles(response.data);                    
                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresaFiles();
    }, [selectEmpresaFiles])

    const cargarArchivo = async(e) => {
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro de subir este achivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {

                /*
                if(!selectedFile || selectedFile === ''){
                    setMsgError('Adjunta un archivo');
                    return;
                }

                if(!selectEmpresa || selectEmpresa === ''){
                    setMsgError('Selecciona una empresa');
                    return;
                }
                */
           
                const formData = new FormData();
                formData.append('file', selectedFile);
                //formData.append('empresaId', selectEmpresa);
                //formData.append('sucursalId', selectSucursal);
                try {
                    setLoadingArchivo(true); // Indicar que se están cargando los datos
                    const response = await axios.post(`https://api.seguroll-asistencias.com/aseguradosRaw`, formData)
                    setMsg(response.data.message)
                    setLoadingArchivo(false); // Indicar que se están cargando los datos
                    setMsg(response.data.msg)
                } catch (error) {
                    if(error.response){
                        setMsgError(error.response.data.msg);
                    }
                }

            }
        });
        
    }

    const handleReporteChange = (e) => {
        const file = e.target.files[0];
        setSelectedReporte(file);
    };

    const handleTituloChange = (event) => {
        setTituloReporte(event.target.value);
    };

    const reporteEmpresa = async(e) => {
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro de subir este achivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {

                /*
                if(!selectedFile || selectedFile === ''){
                    setMsgError('Adjunta un archivo');
                    return;
                }

                if(!selectEmpresa || selectEmpresa === ''){
                    setMsgError('Selecciona una empresa');
                    return;
                }
                */
           
                const formData = new FormData();
                formData.append('file', selectedReporte);                
                formData.append('empresaId', selectEmpresaReportes);
                formData.append('titulo', tituloReporte);

                try {
                    setLoadingReporte(true); // Indicar que se están cargando los datos
                    const response = await axios.post(`https://api.seguroll-asistencias.com/reportes`, formData)
                    setMsgReporte(response.data.message)
                    setLoadingReporte(false); // Indicar que se están cargando los datos
                    setMsgReporte(response.data.msg)
                } catch (error) {
                    if(error.response){
                        setMsgErrorReporte(error.response.data.msg);
                    }
                }

            }
        });

    }


    const handleReporteChangeGlobal = (e) => {
        const file = e.target.files[0];
        setSelectedReporteGlobal(file);
    };

    const handleTituloChangeGlobal = (event) => {
        setTituloReporteGlobal(event.target.value);
    };

    const reporteEmpresaGlobales = async(e) => {        
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro de subir este achivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {
           
                const formData = new FormData();
                formData.append('file', selectedReporteGlobal);
                formData.append('titulo', tituloReporteGlobal);

                try {
                    setLoadingReporteGlobal(true); // Indicar que se están cargando los datos
                    const response = await axios.post(`https://api.seguroll-asistencias.com/reportesGlobales`, formData)
                    setMsgReporteGlobales(response.data.message)
                    setLoadingReporteGlobal(false); // Indicar que se están cargando los datos
                    setMsgReporteGlobales(response.data.msg)
                } catch (error) {
                    if(error.response){
                        setMsgErrorReporteGlobales(error.response.data.msg);
                    }
                }

            }
        });
    }

    const descargarExcel = async(e) => {
        e.preventDefault();
        
        let response;
        try {         
            setLoadingFile(true);   
            response = await axios.get(`https://api.seguroll-asistencias.com/dowloadFileUser`, {
                params: {
                  empresaId: selectEmpresaFiles,
                  sucursalId: selectSucursalFiles,
                },
                responseType: 'arraybuffer',
            });
            
            // Crear un blob a partir de los datos binarios recibidos en la respuesta
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear un objeto URL para el blob
            const url = window.URL.createObjectURL(blob);

            // Crear un enlace de descarga y hacer clic en él para iniciar la descarga del archivo Excel
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx');
            document.body.appendChild(link);
            link.click();

            // Liberar el objeto URL
            window.URL.revokeObjectURL(url);
            setLoadingFile(false);   

        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg);
            }
        }
    }

    const spinnerStyle = css`
        display: block;
        margin: 0 auto;
    `;

    return (

        <div>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Archivos Maestros</h1>
                        <h2 className='subtitle'>Sube tus Cargas Mensuales de Datos</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_archivos} style={imageStyle} alt="icono de archivos" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>
                <Container style={{ maxHeight: "50%"}}>                    
                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={8} lg={10} xs={12}>
                            <div className="alert alert-custom-purple" role="alert">
                                <strong>Instrucciones para cargar tu archivo mensual:</strong>
                                <ol>
                                    <li>Verifica que no haya pólizas vacías. Los demás campos pueden estar ausentes, pero la póliza es obligatoria.</li>
                                    <li>Asegúrate de que tu hoja de Excel se denomine "Hoja1" o "Hoja2".</li>
                                    <li>Es necesario que todos los registros cuenten con una empresa asociada.</li>
                                    <li>Las fechas deben seguir el formato: "AAAA-MM-DD".</li>
                                    <li>La sucursal es opcional.</li>
                                </ol>                                
                            </div>
                            <Card className="shadow">
                                {msgError &&
                                <div className="alert alert-danger" role="alert">
                                    {msgError}
                                </div>
                                }
                                {msg && (
                                    <div className="alert alert-success" role="alert">
                                        {msg}
                                    </div>
                                )}
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-2 pt-0"><strong>Por favor, elige el archivo correspondiente. Ten en mente que estos archivos son los maestros.</strong></p>
                                        <div className="mb-3">
                                            <Form onSubmit={cargarArchivo}>
                                                {loadingArchivo ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Cargando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {msg && (
                                                            <div className="alert alert-success" role="alert">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                <Form.Group className="mb-3" controlId="formBasicEmail">                                                    
                                                    <Form.Label className="text-center">Selecciona un archivo.</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        accept=".xlsx, .xls"
                                                        onChange={handleFileChange}
                                                    />
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Registrar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={8} lg={10} xs={12}>
                            
                            <div className="alert alert-custom-purple" role="alert">
                                <strong>Generación de archivos:</strong>
                                <ol>
                                    <li>Elige la empresa de la que deseas descargar el archivo Excel y, si lo prefieres, también puedes especificar una sucursal.</li>
                                </ol>
                            </div>

                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-2 pt-0"><strong>Elige la empresa de la que deseas descargar el archivo Excel y, si lo prefieres, también puedes especificar una sucursal.</strong></p>
                                        <div className="mb-3">
                                            <Form onSubmit={descargarExcel}>
                                                {/*msg &&
                                                    <div className="alert alert-success" role="alert">
                                                        {msg}
                                                    </div>
                                                    */}
                                                {loadingFile ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Generando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {msg &&
                                                            <div className="alert alert-success" role="alert">
                                                                <p>Archivo generado con éxito</p>
                                                            </div>
                                                        }
                                                    </>
                                                )}

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">Selecciona la empresa</Form.Label>
                                                    <Form.Control
                                                    as="select"
                                                    value={selectEmpresaFiles} 
                                                    onChange={(e) => setSelectEmpresaFiles(e.target.value)}
                                                    >
                                                    <option value="">Selecciona una opción</option>
                                                    {empresasFiles.map(empresa => (
                                                        <option key={empresa.id} value={empresa.id}>
                                                            {empresa.nombre}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">Selecciona la sucursal</Form.Label>
                                                    <Form.Control
                                                    as="select"
                                                    value={selectSucursalFiles}
                                                    onChange={(e) => setSelectSucursalFiles(e.target.value)}
                                                    >
                                                    <option value="">Selecciona una opción</option>
                                                    {sucursalesFiles.map(sucursal => (
                                                        <option key={sucursal.nombre} value={sucursal.id}>
                                                            {sucursal.nombre}
                                                        </option>
                                                    ))}                                                    
                                                    </Form.Control>
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Descargar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={8} lg={10} xs={12}>
                            <div className="alert alert-custom-purple" role="alert">
                                <strong>Instrucciones para cargar tu reporte por empresa:</strong>
                                <ol>
                                    <li>Selecciona la empresa.</li>
                                    <li>Asegúrate de tener el reporte listo para adjuntar.</li>
                                </ol>                                
                            </div>
                            <Card className="shadow">
                                {msgErrorReporte &&
                                <div className="alert alert-danger" role="alert">
                                    {msgErrorReporte}
                                </div>
                                }
                                {msgReporte && (
                                    <div className="alert alert-success" role="alert">
                                        {msgReporte}
                                    </div>
                                )}
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-2 pt-0"><strong>Por favor, elige el archivo correspondiente.</strong></p>
                                        <div className="mb-3">
                                            <Form onSubmit={reporteEmpresa}>
                                                {loadingReporte ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Cargando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {msgReporte && (
                                                            <div className="alert alert-success" role="alert">
                                                                {msgReporte}
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                <Form.Group className="mb-3" controlId="formBasicEmail">                                                    
                                                    <Form.Label className="text-center">Selecciona un archivo.</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        accept=".xlsx, .xls, .pdf"
                                                        onChange={handleReporteChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicTitle">
                                                    <Form.Label className="text-center">Título del Documento</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Ingresa el título"
                                                        value={tituloReporte}
                                                        onChange={handleTituloChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">Selecciona la empresa</Form.Label>
                                                    <Form.Control
                                                    as="select"
                                                    value={selectEmpresaReportes} 
                                                    onChange={(e) => setSelectEmpresaReportes(e.target.value)}
                                                    >
                                                    <option value="">Selecciona una opción</option>
                                                    {empresasReportes.map(empresa => (
                                                        <option key={empresa.id} value={empresa.id}>
                                                            {empresa.nombre}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Registrar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={8} lg={10} xs={12}>
                            <div className="alert alert-custom-purple" role="alert">
                                <strong>Instrucciones para cargar tu reporte global, este archivo unicamente estara disponible para administradores:</strong>
                                <ol>
                                    <li>Asegúrate de tener el reporte listo para adjuntar.</li>
                                </ol>                                
                            </div>
                            <Card className="shadow">
                                {msgErrorReporteGlobales &&
                                <div className="alert alert-danger" role="alert">
                                    {msgErrorReporteGlobales}
                                </div>
                                }
                                {msgReporteGlobales && (
                                    <div className="alert alert-success" role="alert">
                                        {msgReporteGlobales}
                                    </div>
                                )}
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-2 pt-0"><strong>Por favor, elige el archivo correspondiente.</strong></p>
                                        <div className="mb-3">
                                            <Form onSubmit={reporteEmpresaGlobales}>
                                                {loadingReporteGlobal ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Cargando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {msgReporteGlobales && (
                                                            <div className="alert alert-success" role="alert">
                                                                {msgReporteGlobales}
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                <Form.Group className="mb-3" controlId="formBasicEmail">                                                    
                                                    <Form.Label className="text-center">Selecciona un archivo.</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        accept=".xlsx, .xls, .pdf"
                                                        onChange={handleReporteChangeGlobal}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicTitle">
                                                    <Form.Label className="text-center">Título del Documento</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Ingresa el título"
                                                        value={tituloReporteGlobal}
                                                        onChange={handleTituloChangeGlobal}
                                                    />
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Registrar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </div>

    )

}

export default CargarArchivo