import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import empresa_icon from '../img/empresa_icon.png'
import axios from 'axios';
import Swal from 'sweetalert2';

const Empresaslist = () => {

  // Declarar imageStyle aquí, en el alcance del componente Navbar
  const [imageStyle, setImageStyle] = useState({});

  const applyImageStyles = () => {
    const smallScreen = window.matchMedia('(max-width: 576px)').matches;
    const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
    const largeScreen = window.matchMedia('(min-width: 769px)').matches;

    if (smallScreen) {
      setImageStyle({ maxWidth: '75%' });
    } else if (mediumScreen) {
      setImageStyle({ maxWidth: '35%' });
    } else if (largeScreen) {
      setImageStyle({ maxWidth: '20%' });
    }
  };

  useEffect(() => {
    applyImageStyles();
    window.addEventListener('resize', applyImageStyles);
    return () => window.removeEventListener('resize', applyImageStyles);
  }, []);

  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    getEmpresas();
  }, []);

  const getEmpresas = async () => {
    try {
      const response = await axios.get('https://api.seguroll-asistencias.com/empresas');
      setEmpresas(response.data); 
    } catch (error) {
      console.log(error.message)
    }    
  }

  const deleteEmpresa = async (empresaId) => {
    
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => { 
      if (result.isConfirmed) {
        await axios.delete(`https://api.seguroll-asistencias.com/empresas/${empresaId}`);
        getEmpresas();
      }      
    });

  }

  return (
    <div>
        
        <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <h1 className='title'>Empresas</h1>
                <h2 className='subtitle'>Lista de empresas</h2>
                <Link to="/empresas/add" className='btn btn-light text-primary'>AÑADIR EMPRESA</Link>
              </div>
              <div className='col text-end'>
                <img src={empresa_icon} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
              </div>
            </div>            
          </div>
        </div>

        <div className='card mt-0 shadow p-3 mb-5 bg-body rounded'>
          <div className='table-responsive' style={{ maxHeight: "500px" }}>
            <table className="table table-striped align-middle table-hover">
                <thead>
                    <tr>
                    <th className='text-center'>No</th>
                    <th className='text-center'>Nombre</th>
                    <th className='text-center'>Encargados</th>
                    <th className='text-center'>Acciónes</th>
                    </tr>
                </thead>
                <tbody>
                    {empresas.map((empresa, index) => (
                      <tr key={empresa.uuid}>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-center'>
                          <Link to={`/empresas/registros/${empresa.uuid}`} className='btn btn-light'>{empresa.nombre}</Link>
                        </td>
                        <td className='text-center'>
                          <Link to={`/empresas/encargados/${empresa.uuid}`} className='btn btn-light'>Ver encargados</Link>
                        </td>
                        <td className='text-center'>
                          <Link to={`/empresas/edit/${empresa.uuid}`} className='btn btn-info m-1'>Editar</Link>
                          <button onClick={() => deleteEmpresa(empresa.uuid)} className='btn btn-secondary'>Borrar</button>
                        </td>
                      </tr>
                    ))}
                </tbody>
            </table>
          </div>
        </div>        

    </div>
  )
}

export default Empresaslist