import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import icono_usuario from "../img/icono_usuario3.png"
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap'; // Asegúrate de importar los componentes de Bootstrap correctamente
import { useMemo } from 'react';
import DataTable from './DataTables';
import Swal from "sweetalert2";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';
import { useCameraPermission } from './useCameraPermission'; // Asegúrate de tener este archivo o crearlo

import icono_img from "../img/foto.png"
import icono_video from "../img/play.png"
import icono_pdf from "../img/pdf.png"
import icono_audio from "../img/audio.png"
import icono_archivos from "../img/carpeta.png"
import icono_adjunto from "../img/adjunto.png";
import './table.css'

const MediaList = () => {

    const { hasPermission, requestPermission } = useCameraPermission(); // Llama al hook

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const {user} = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [descripcionMedia, setDescripcionMedia] = useState('');
    const [media, setMedia] = useState([]);

    const [loadingFile, setLoadingFile] = useState(false);

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '13%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const columns = useMemo(
        () => {
            const baseColumns = [
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Nombre del archivo</div>
                    ),
                    accessor: 'userName',
                    Cell: ({ row }) => (
                        <div className="file-container">
                            <div className="file-icon">
                                {getFileIcon(row.original.fileName)}
                            </div>
                            <div className="file-name">
                                {row.original.userName}
                            </div>
                        </div>
                    )
                },
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Descripción</div>
                    ),
                    accessor: 'description',
                }
            ];

            if (user) {
                if (user.role === "admin") {
                    baseColumns.push({
                        Header: () => (
                            <div
                                style={{
                                    textAlign: "center"
                                }}
                            >Acciones</div>
                        ),
                        accessor: 'actions',
                        Cell: ({ row }) => (
                            <div>
                                <button
                                onClick={() => downloadMedia(row.original.uuid)}
                                className="btn btn-info m-1"
                                >
                                    Descargar
                                </button>
                                <button onClick={() => deleteMedia(row.original.uuid)} className='btn btn-secondary'>Borrar</button>
                            </div>
                        ),
                    });
                } else {
                    baseColumns.push({
                        Header: () => (
                            <div
                                style={{
                                    textAlign: "center"
                                }}
                            >Acciones</div>
                        ),
                        accessor: 'actions',
                        Cell: ({ row }) => (                            
                            <button
                                onClick={() => downloadMedia(row.original.uuid)}
                                className="btn btn-info m-1"
                                >
                                    Descargar
                            </button>
                        ),
                    });
                }
            }
        
          return baseColumns;
        },
        [user]
    );

    function getFileIcon(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        const iconMap = {
            pdf: <img src={icono_pdf} alt="PDF" style={{ width: '20px', height: '20px' }}/>,
            jpg: <img src={icono_img} alt="JPG" style={{ width: '20px', height: '20px' }}/>,
            png: <img src={icono_img} alt="PNG" style={{ width: '20px', height: '20px' }}/>,
            docx: <img src={icono_archivos} alt="DOCX" style={{ width: '20px', height: '20px' }}/>,
            mp4: <img src={icono_video} alt="MP4" style={{ width: '20px', height: '20px' }}/>, // Icono para videos MP4
            avi: <img src={icono_video} alt="AVI" style={{ width: '20px', height: '20px' }}/>, // Icono para videos AVI
            mp3: <img src={icono_audio} alt="MP3" style={{ width: '20px', height: '20px' }}/>, // Icono para archivos de audio MP3
            wav: <img src={icono_audio} alt="WAV" style={{ width: '20px', height: '20px' }}/>, // Icono para archivos de audio WAV
            // ... Otros tipos de archivo y sus iconos ...
        };
    
        return iconMap[extension] || <img src={icono_adjunto} alt="Archivo" style={{ width: '20px', height: '20px' }}/>;
    }

    useEffect(() => {
        if (user) {
            getMedia();
        }
    }, [user]);

    const deleteMedia = async (mediaId) => {

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => { 
        
            if (result.isConfirmed) {
                try {
                    await axios.delete(`https://api.seguroll-asistencias.com/media/${mediaId}`);            
                    getMedia();
                } catch (error) {
                    if(error.response){
                        console.log(error.response);
                    }
                }
            }

        });

    }

    const downloadMedia = async (uuid) => {
        try {
            setLoading(true);

            const response = await axios.get(`https://api.seguroll-asistencias.com/media/${uuid}`);
            const signedUrl = response.data.signedUrl;

            // Usar la URL prefirmada, por ejemplo, crear un enlace para la descarga
            const link = document.createElement('a');
            link.href = signedUrl;
            link.innerText = 'Descargar archivo';
            link.click();

            /*
            const response = await axios.get(`https://api.seguroll-asistencias.com/media/${uuid}`, {
                responseType: 'blob', // Indicar que la respuesta es un archivo binario
            });

            const filename = getFilenameFromResponseHeaders(response);

            const contentType = response.headers['content-type'];

            console.log(contentType);            

            // Crear un enlace temporal y simular un clic para iniciar la descarga
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));

            // Establecer el tipo de contenido en el enlace
            link.type = contentType;

            link.download = filename;
            link.click();

            /*
            const contentType = response.headers['content-type'];

            // Crear un enlace temporal y simular un clic para iniciar la descarga
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
            link.download = filename;
            link.click();
            */

            setLoading(false);
        } catch (error) {
            console.error(error.message);
        }
    }

    function getFilenameFromResponseHeaders(response) {
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename=["']?([^"']+)/);
            if (filenameMatch && filenameMatch.length > 1) {
                return filenameMatch[1];
            }
        }
        return 'archivo-descargado'; // Valor por defecto en caso de no encontrar el nombre del archivo
    }

    const getMedia = async () => {        
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/media`);
            setDataTable(response.data)
            setMedia(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {            
            console.log(error.message);
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };
    
    const registroMedia = async (e) => {

        e.preventDefault();

        if (hasPermission) {

            if (selectedFile) {
                setLoadingFile(true);   
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('descripcion', descripcionMedia); // Agrega la descripción al formData
    
                try {
                    await axios.post('https://api.seguroll-asistencias.com/media', formData); // Cambia la URL por la ruta de tu backend
                    getMedia();
                    setLoadingFile(false);  
                } catch (error) {
                    console.error('Error al subir archivo:', error);
                    setLoadingFile(false);  
                }
            }

        } else {            
            requestPermission();
        }

    };

    const spinnerStyle = css`
        display: block;
        margin: 0 auto;
    `;

    return (

        <div className='container-fluid'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Media</h1>
                        <h5 className='subtitle'>Lista de archivos multimedia</h5>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_usuario} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            {user && user.role === "admin" ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <h5 className="mt-2 mb-4">Subir nuevo archivo multimedia</h5>
                        <Row className="d-flex justify-content-center align-items-center mt-3 mb-3">
                            <Col md={8} lg={10} xs={12}>
                                <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                    <p className=" mb-2 pt-0">
                                        <strong>Selecciona un nuevo archivo multimedia</strong>
                                    </p>
                                    <div className="mb-3">
                                        <form onSubmit={registroMedia}>
                                        <div className="row">
                                            <div className="col">
                                            <Form.Group className="mt-5">
                                                <label htmlFor="archivoInput">Archivo:</label>
                                                <input
                                                type="file"
                                                id="archivoInput"
                                                //accept=".pdf,.jpg,.png,.docx" // Puedes ajustar las extensiones permitidas
                                                onChange={handleFileChange}
                                                className="form-control"
                                                style={{ height: "100%" }}
                                                />
                                            </Form.Group>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                            <Form.Group className="mt-5">
                                                <label htmlFor="descripcionInput">Descripción:</label>
                                                <textarea
                                                id="descripcionInput"
                                                placeholder="Descripción del archivo multimedia"
                                                value={descripcionMedia}
                                                onChange={(e) => setDescripcionMedia(e.target.value)}
                                                className="form-control"
                                                rows="3"
                                                ></textarea>
                                            </Form.Group>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center mt-5">
                                            <div className="col text-center">
                                            {loadingFile ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Cargando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        className="btn btn-dark"
                                                        variant="primary"
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>
                                            )}
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                    </div>
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columns} data={dataTable} />
                            )}
                        </>
                    )}
                </div>
            </div>

        </div>

    )

}

export default MediaList