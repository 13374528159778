import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../img/user_dark.png"
import logo from "../img/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import { Link } from "react-router-dom";
import "bootstrap";
import axios from "axios";
import './table.css'

const Navbar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  }

  // Declarar imageStyle aquí, en el alcance del componente Navbar
  const [imageStyle, setImageStyle] = useState({});

  const applyImageStyles = () => {
    const smallScreen = window.matchMedia('(max-width: 576px)').matches;
    const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
    const largeScreen = window.matchMedia('(min-width: 769px)').matches;

    if (smallScreen) {
      setImageStyle({ maxWidth: '20%' });
    } else if (mediumScreen) {
      setImageStyle({ maxWidth: '10%' });
    } else if (largeScreen) {
      setImageStyle({ maxWidth: '5%' });
    }
  };

  useEffect(() => {
    applyImageStyles();
    window.addEventListener('resize', applyImageStyles);
    return () => window.removeEventListener('resize', applyImageStyles);
  }, []);

  const [pendingOrInProgressCount, setPendingOrInProgressCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      //if (user && user.role === 'admin') {
        try {
          const response = await axios.get('https://api.seguroll-asistencias.com/quejas-count');          
          setPendingOrInProgressCount(response.data)
        } catch (error) {
          
        }
      //}
    };
  
    fetchData(); // Llama a la función async definida arriba
  }, [user]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light text-dark p-3 fixed-top" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
      {/*<nav className="navbar navbar-expand-lg navbar-dark bg-dark p-3 fixed-top">*/}
        <div className="container-fluid">
          
          <img src={logo} style={imageStyle} className="img-fluid" />

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        
          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link mx-2 active" aria-current="page" href="/dashboard" style={{ textShadow: '0 0 5px white', color: "black" }}>Inicio</a>
              </li>

              <li className="nav-item">
                <a className="nav-link mx-2" href="/asegurados" style={{ textShadow: '0 0 5px white', color: "black" }}>Polizas</a>
              </li>

              <li className="nav-item">
                <a className="nav-link mx-2" href="/media" style={{ textShadow: '0 0 5px white', color: "black" }}>Multimedia</a>
              </li>

              {user ? (
                <li className="nav-item">
                  <a className="nav-link mx-2" href={`/quejas/${user.uuid}`} style={{ textShadow: '0 0 5px white', color: "black" }}>Quejas
                    {pendingOrInProgressCount > 0 && (
                      <span className="badge">{pendingOrInProgressCount}</span>
                    )}
                  </a>
                </li>
              ): null}

              {user && user.role === "admin" ? (
                <>                  
                  <li className="nav-item">
                    <a className="nav-link mx-2" href="/empresas" style={{ textShadow: '0 0 5px white', color: "black" }}>Empresas</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link mx-2" href="/users" style={{ textShadow: '0 0 5px white', color: "black" }}>Usuarios</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link mx-2" href="/carga-archivo" style={{ textShadow: '0 0 5px white', color: "black" }}>Cargar / Descarga archivo Excel</a>
                  </li>
                </>
              ) : null}

              <li className="nav-item dropdown">
                <a href="#" className="nav-link mx-2 dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" style={{ textShadow: '0 0 5px white', color: "black" }} aria-expanded="false">
                  <img src={avatar} alt="Imagen del menu de sesión" width="32" height="32" className="rounded-circle me-2" />
                </a>
                <ul className="dropdown-menu dropdown-menu-end" style={{ backgroundColor: 'rgba(255, 255, 255, 1)', backdropFilter: 'blur(15px)' }} aria-labelledby="navbarDropdownMenuLink">
                  <li className="nav-item text-center">                    
                  {user && (
                    user.role === 'admin' ? (
                      <Link className="nav-link mx-2 mb-2 text-dark" to={`/users/edit/${user.uuid}`}>Perfil</Link>
                    ) : (
                      <Link className="nav-link mx-2 mb-2 text-dark" to={`/users/profile/${user.uuid}`}>Perfil</Link>
                    )
                  )}
                  </li>
                  <li className="nav-item text-center">
                    <button onClick={logout} type="button" className="btn btn-outline-link text-dark">Log Out</button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
	    </nav>
    </div>
  )
}

export default Navbar