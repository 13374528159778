import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import icono_usuario3 from '../img/icono_usuario3.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import DataTable from './DataTables';
import './table.css';
//import DataTableUsers from './DataTableUsers'
import Swal from 'sweetalert2';

const Encargados = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth);
    const {empresaId} = useParams();
    const [msg, setMsg] = useState("");
    const [dataTable, setDataTable] = useState([]);

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;        

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    useEffect(() => {
        if(user && user.role === "admin"){
            getUsuariosEmpresa();
        }
    }, [user, empresaId])

    const getUsuariosEmpresa = async () => {
        try{
            setLoading(true);
            const response = await axios.get(`https://api.seguroll-asistencias.com/usuarios-empresas/${empresaId}`);            
            setDataTable(response.data.usuarios_empresas)
            setLoading(false);
        }catch (error) {
            console.log(error.message);
        }
    }

    // Definir las columnas para la tabla
    const columns = [
        {   
            Header: () => (
                <div
                  style={{
                    textAlign:"center"
                  }}
                >Usuario</div>
            ),
            accessor: 'usuario.nombre',            
            Cell: ({ row }) => (
                <>
                    <Link to={`/users/edit/${row.original.usuario.uuid}`} className='btn btn-link-gray m-1'>{row.original.usuario.nombre} {row.original.usuario.apellido_paterno} {row.original.usuario.apellido_materno}</Link>
                </>
            ),
        },
        {
            Header: 'Sucursales',
            accessor: 'usuario.permisos_sucursales',
            Cell: ({ value }) => {
                if (value.length === 0) {
                    return (
                        <ul style={{ textAlign: 'left' }}>
                            <li>Acceso General</li>
                        </ul>
                    );                  
                } else {
                  return (
                    <ul style={{ textAlign: 'left' }}>
                      {value.map((permiso, index) => (
                        <li key={index}>{permiso.sucursale.nombre}</li>
                      ))}
                    </ul>
                  );
                }
            },
        },
    ];

    return (

        <div>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Empresas</h1>
                        <h2 className='subtitle'>Usuarios asignados</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_usuario3} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div>
                {/*loading ? (
                    <p>Cargando datos...</p>
                ) : (
                    <DataTable columns={columns} data={dataTable} />
                )*/}
                {dataTable.length === 0 ? (
                    <p>Sin información disponible.</p>
                ) : (
                <>
                    {dataTable.some((element) => element === undefined) ? (
                    <p>Cargando datos...</p>
                    ) : (
                        <DataTable columns={columns} data={dataTable} />
                    )}
                </>
                )}
            </div>

        </div>

    )

}

export default Encargados