import React, {useEffect, useState} from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import icono_edit_empresa from '../img/icono_edit_empresa.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import DataTable from './DataTableAsegurados';
import Swal from 'sweetalert2';

const EmpresaRegistro = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth);
    const {empresaId} = useParams();
    const [msg, setMsg] = useState("");

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;        

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        if (user && user.role === "admin") {
          getAsegurados();
          getAseguradosAltas();
          getAseguradosBajas();
        }
    }, [user, empresaId]);

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [dataTable, setDataTable] = useState([]);
    const [dataTableAltas, setDataTableAltas] = useState([]);
    const [dataTableBajas, setDataTableBajas] = useState([]);

    // Define una función de filtro personalizada para la columna "Nombre"
    function NombreColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por nombre..."
        />
      );
    }

    function EmpresaColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por empresa..."
        />
      );
    }

    function SucursalColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por sucursal..."
        />
      );
    }

    function PolizaColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por poliza..."
        />
      );
    }

    function ApellidoPaternoColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por apellido paterno..."
        />
      );
    }

    function ApellidoMaternoColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por apellido materno..."
        />
      );
    }

    function RfcColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Filtrar por RFC..."
        />
      );
    }

    const columns = useMemo(
        () => {
          const baseColumns = [
            {
                Header: 'Poliza',
                accessor: 'poliza',
                Filter: PolizaColumnFilter,
                Cell: ({ row }) => (
                  <>
                    {user && user.role === "admin" ? (
                      <Link to={`/historial/${row.original.poliza}`} className='btn btn-outline-dark m-1'>
                        {row.original.poliza}
                      </Link>
                    ) : (
                      <span>{row.original.poliza}</span>
                    )}
                  </>
                ),
              },
              {
                Header: 'Empresa',
                accessor: 'empresa.nombre',
                Filter: EmpresaColumnFilter,
              },
              {
                Header: 'Sucursal',
                accessor: 'sucursal.nombre',
                Filter: SucursalColumnFilter,
              },
              {
                Header: 'Nombre',
                accessor: 'nombre',
                Filter: NombreColumnFilter,
              },
              {
                Header: 'Apellido paterno',
                accessor: 'apellido_paterno',
                Filter: ApellidoPaternoColumnFilter,
              },
              {
                Header: 'Apellido materno',
                accessor: 'apellido_materno',
                Filter: ApellidoMaternoColumnFilter,
              },
              {
                Header: 'RFC',
                accessor: 'rfc',
                Filter: RfcColumnFilter,
              },
              {
                Header: 'F. nacimiento',
                accessor: 'fecha_nacimiento',
              },
              {
                Header: 'Inicio vigencia',
                accessor: 'inicio_vigencia',
              },
              {
                Header: 'Fin vigencia',
                accessor: 'fin_vigencia',
              }
          ];
    
          if (user && user.role === "admin") {
            baseColumns.push({
              Header: 'Acciones',
              accessor: 'actions',
              Cell: ({ row }) => (
                <div>
                  <Link to={`/asegurados/edit/${row.original.uuid}`} className='btn btn-info m-1'>Editar</Link>
                  <button onClick={() => deleteAfiliado(row.original.uuid)} className='btn btn-secondary'>Borrar</button>
                </div>
              ),
            });
          }
    
          return baseColumns;
        },
        [user]
    );

    const columnsEspcial = useMemo(
        () => {
          const baseColumns = [
            {
                Header: 'Poliza',
                accessor: 'poliza',
                Filter: PolizaColumnFilter,
                Cell: ({ row }) => (
                  <>
                    {user && user.role === "admin" ? (
                      <Link to={`/historial/${row.original.poliza}`} className='btn btn-outline-dark m-1'>
                        {row.original.poliza}
                      </Link>
                    ) : (
                      <span>{row.original.poliza}</span>
                    )}
                  </>
                ),
              },
              {
                Header: 'Empresa',
                accessor: 'empresa.nombre',
                Filter: EmpresaColumnFilter,
              },
              {
                Header: 'Sucursal',
                accessor: 'sucursal.nombre',
                Filter: SucursalColumnFilter,
              },
              {
                Header: 'Nombre',
                accessor: 'nombre',
                Filter: NombreColumnFilter,
              },
              {
                Header: 'Apellido paterno',
                accessor: 'apellido_paterno',
                Filter: ApellidoPaternoColumnFilter,
              },
              {
                Header: 'Apellido materno',
                accessor: 'apellido_materno',
                Filter: ApellidoMaternoColumnFilter,
              },
              {
                Header: 'Inicio vigencia',
                accessor: 'inicio_vigencia',
              },
              {
                Header: 'Fin vigencia',
                accessor: 'fin_vigencia',
              }
          ];
    
          if (user && user.role === "admin") {
            baseColumns.push({
              Header: 'Acciones',
              accessor: 'actions',
              Cell: ({ row }) => (
                <div>
                  <p>No disponibles</p>
                </div>
              ),
            });
          }
    
          return baseColumns;
        },
        [user]
    );

    const [aseguradosList, setAsegurados] = useState([]);
    const [aseguradosListAltas, setAseguradosAltas] = useState([]);
    const [aseguradosListBajas, setAseguradosBajas] = useState([]);

    const getAsegurados = async () => {
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/aseguradosByEmpresa/${empresaId}`);
            setDataTable(response.data)
            setAsegurados(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {
            console.log(error.message);
        }
    }

    const getAseguradosAltas = async () => {
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/aseguradosAltasByEmpresa/${empresaId}`);            
            setDataTableAltas(response.data)
            setAseguradosAltas(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {
            console.log(error.message);
        }
    }

    const getAseguradosBajas = async () => {
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/aseguradosBajasByEmpresa/${empresaId}`);            
            setDataTableBajas(response.data)
            setAseguradosBajas(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {
            console.log(error.message);
        }
    }

    // Eliminamos a los usuarios
    const deleteAfiliado = async (aseguradoId) => {

        Swal.fire({
          title: '¿Estás seguro?',
          text: 'Esta acción no se puede deshacer',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, estoy seguro',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => { 
          
          if (result.isConfirmed) {
              try {
  
                await axios.delete(`https://api.seguroll-asistencias.com/asegurados/${aseguradoId}`);
                setDataTable((dataTable) => dataTable.filter((item) => {
                    return item.uuid !== aseguradoId;
                }));
                getAsegurados();
  
              } catch (error) {
                  if(error.response){
                      setMsg(error.response.data.msg);
                  }
              }
          }        
  
        });        
  
      }

    return (

        <div>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Gestión de Empresa</h1>
                        <h2 className='subtitle'>Historial de registros, altas y bajas</h2>
                        <Link to={`/historial-general/${empresaId}`} className='btn btn-light text-primary'>Ver historial general de polizas</Link>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_edit_empresa} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className="alert alert-primary" role="alert">
                <strong>Filtro de búsqueda:</strong>                
                <p>Para filtrar la tabla, simplemente introduce un valor, como una fecha, nombre, número de póliza, u otro.</p>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>                
                <h4 className=' subtitle mt-2 mb-3'>Asegurados Activos</h4>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columns} data={dataTable} />
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>                
                <h4 className=' subtitle mt-2 mb-3'>Asegurados ingresados recientemente</h4>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columnsEspcial} data={dataTableAltas} />
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>                
                <h4 className=' subtitle mt-2 mb-3'>Asegurados inactivos</h4>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {/*loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTableBajas.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columnsEspcial} data={dataTableBajas} />
                            )}
                        </>
                            )*/}

                  {dataTableBajas.length === 0 ? (
                      <p>Sin información disponible.</p>
                  ) : (
                    <>
                        {dataTableBajas.some((element) => element === undefined) ? (
                        <p>Cargando datos...</p>
                        ) : (
                            <DataTable columns={columns} data={dataTableBajas} />
                        )}
                    </>
                  )}
                </div>
            </div>

        </div>

    )

}

export default EmpresaRegistro