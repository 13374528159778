import React, {useEffect, useState} from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_edi_user from '../img/icono_edi_user.png'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

const FromEditUser = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    const [verReportes, setVerReportes] = useState(false); // Nuevo estado para el checkbox
    const [reportes, setReportes] = useState([]);

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [poliza, setPoliza] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido_paterno, setApellidoPaterno] = useState("");
    const [apellido_materno, setApellidoMaterno] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confiPassword, setConfiPassword] = useState("");
    const [rol, setRol] = useState("");
    
    const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState([]);
    const [empresasActuales, setEmpresasActuales] = useState([]);
    const [empresas, setEmpresas] = useState([]);

    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    // Permisos de empresas y sucursales
    const [permisosEmpresasSeleccionados, setPermisosEmpresasSeleccionados] = useState({});
    const [permisosSucursalesSeleccionados, setPermisosSucursalesSeleccionados] = useState({});
    const [combinedPermissions, setCombinedPermissions] = useState({});

    useEffect(() => {
        getReportes();
    }, [id]);

    const getReportes = async () => {
        try {
            let response;
            if(id){
                response = await axios.get(`https://api.seguroll-asistencias.com/reportesUser/${id}`);
                setReportes(response.data);
                setVerReportes(response.data.length > 0);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        const getUserbyId = async () => {
            try {
                const response = await axios.get(`https://api.seguroll-asistencias.com/users/${id}`);
                setPoliza(response.data.poliza);
                setNombre(response.data.nombre);
                setApellidoPaterno(response.data.apellido_paterno);
                setApellidoMaterno(response.data.apellido_materno);
                setEmail(response.data.email);
                setEmpresasActuales(response.data.usuarios_empresas);
                setRol(response.data.role);
                
                // Obtenemos los permisos del usuario
                const permisosEmpresas = response.data.usuarios_empresas || [];
                const permisosSucursales = response.data.permisos_sucursales || [];

                // Crea objetos que mapeen el ID de la empresa y la sucursal a los permisos seleccionados
                const permisosEmpresasSeleccionados = {};
                const permisosSucursalesSeleccionados = {};

                permisosEmpresas.forEach((empresa) => {
                    permisosEmpresasSeleccionados[empresa.empresaId] = [];
                });
                
                permisosSucursales.forEach((sucursal) => {
                if (!permisosSucursalesSeleccionados[sucursal.empresaId]) {
                    permisosSucursalesSeleccionados[sucursal.empresaId] = [];
                }
                permisosSucursalesSeleccionados[sucursal.empresaId].push(sucursal.sucursalId);
                });
                                
                // Actualiza el estado con las empresas actuales del usuario
                setEmpresasActuales(response.data.usuarios_empresas || []);

                empresas.forEach((empresa) => {
                const hasEmpresaPermission = empresasActuales.find((e) => e.empresaId === empresa.id);
                if (hasEmpresaPermission) {
                    permisosEmpresasSeleccionados[empresa.id] = [];
                    permisosSucursalesSeleccionados[empresa.id] = hasEmpresaPermission.sucursales.map((sucursal) => sucursal.id);
                }
                });

                setPermisosEmpresasSeleccionados(permisosEmpresasSeleccionados);
                setPermisosSucursalesSeleccionados(permisosSucursalesSeleccionados);
                
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getEmpresas();
        getUserbyId();
    }, [id]);

    
    const handlePermisoEmpresaChange = (event, empresaId) => {
        const isChecked = event.target.checked;
      
        if (isChecked) {
          // Si se selecciona una empresa, agregar todas las sucursales asociadas
          const allSucursales = empresas.find((empresa) => empresa.id === empresaId)?.sucursales.map((sucursal) => sucursal.id);
          setPermisosEmpresasSeleccionados((prevState) => ({
            ...prevState,
            [empresaId]: [],
          }));
          setPermisosSucursalesSeleccionados((prevState) => ({
            ...prevState,
            [empresaId]: allSucursales || [],
          }));
        } else {
          // Si se deselecciona la empresa, eliminarla de permisosEmpresasSeleccionados y permisosSucursalesSeleccionados
          setPermisosEmpresasSeleccionados((prevState) => {
            const newState = { ...prevState };
            delete newState[empresaId];
            return newState;
          });
          setPermisosSucursalesSeleccionados((prevState) => {
            const newState = { ...prevState };
            delete newState[empresaId];
            return newState;
          });
        }
    };
    
    const handlePermisoSucursalChange = (event, empresaId) => {
        const sucursalId = parseInt(event.target.value, 10);
        const isChecked = event.target.checked;
    
        setPermisosSucursalesSeleccionados((prevState) => {
          const selectedSucursales = prevState[empresaId] || [];
          const updatedSucursales = isChecked
            ? [...selectedSucursales, sucursalId]
            : selectedSucursales.filter((id) => id !== sucursalId);
    
          return {
            ...prevState,
            [empresaId]: updatedSucursales,
          };
        });
    
        // Si la empresa no estaba seleccionada previamente, también agregamos la entrada en permisosEmpresasSeleccionados
        if (!permisosEmpresasSeleccionados[empresaId]) {
          setPermisosEmpresasSeleccionados((prevState) => ({
            ...prevState,
            [empresaId]: [],
          }));
        }
    };
    

    const getEmpresas = async () => { // Recuperamos todas las empresas no las del usuario
        try {
        const response = await axios.get('https://api.seguroll-asistencias.com/empresas');        
        setEmpresas(response.data); 
        } catch (error) {
        console.log(error.message)
        }    
    }   

    
    useEffect(() => {
        const combinedPermissions = {};
      
        Object.keys(permisosEmpresasSeleccionados).forEach((empresaId) => {
          const sucursalesSeleccionadas = permisosSucursalesSeleccionados[empresaId] || [];
          combinedPermissions[empresaId] = sucursalesSeleccionadas;
        });
      
        // Actualiza el estado con el objeto combinado
        setCombinedPermissions(combinedPermissions);
    }, [permisosEmpresasSeleccionados, permisosSucursalesSeleccionados]);

    const updateUser = async(e) => {
        e.preventDefault();        

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {

                try {
                    await axios.patch(`https://api.seguroll-asistencias.com/users/${id}`, {
                        poliza: poliza,
                        nombre: nombre,
                        apellido_paterno: apellido_paterno,
                        apellido_materno: apellido_materno,
                        empresa_user: 0,
                        fecha_nacimiento: '1901-01-01',
                        email: email,
                        password: password,
                        confPassword: confiPassword,
                        role: rol,
                        empresaId: 0,
                        combinedPermissions: combinedPermissions,
                        verReportes: verReportes
                    });
                    navigate("/users")
                } catch (error) {
                    if(error.response){
                        setMsg(error.response.data.msg)
                    }
                }

            }
        });        
    }

    return (
        <div className='mb-5'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Empresas</h1>
                        <h2 className='subtitle'>Editar usuario</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_edi_user} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>

                <Container style={{ maxHeight: "50%"}}>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={8} lg={10} xs={12}>
                            <Card className="shadow">                            
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">                                    
                                        <p className=" mb-5 pt-0">*Todos los campos son obligatorios</p>
                                        <div className="mb-3">
                                            <Form onSubmit={updateUser}>
                                                {msg &&
                                                    <div className="alert alert-danger" role="alert">
                                                        {msg}
                                                    </div>
                                                }
                                                <Form.Group className="mb-3" controlId="formBasicPoliza">
                                                    <Form.Label className="text-center">
                                                    No. Poliza
                                                    </Form.Label>
                                                    <Form.Control value={poliza} onChange={(e) => setPoliza(e.target.value)} type="text" placeholder="Escribe tu numero de poliza" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                    Nombre
                                                    </Form.Label>
                                                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Escribe tu nombre" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoP">
                                                    <Form.Label className="text-center">
                                                    Apellido paterno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_paterno} onChange={(e) => setApellidoPaterno(e.target.value)} type="text" placeholder="Escribe tu apellido paterno" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoM">
                                                    <Form.Label className="text-center">
                                                    Apellido materno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_materno} onChange={(e) => setApellidoMaterno(e.target.value)} type="text" placeholder="Escribe tu apellido materno" />
                                                </Form.Group>                                            

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                    Correo Electronico
                                                    </Form.Label>
                                                    <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Escribe tu correo electronico" />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicPassword"
                                                >
                                                    <Form.Label>Contraseña para el usuario</Form.Label>
                                                    <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="******" />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicConfigPassword"
                                                >
                                                    <Form.Label>Confirma la contraseña para el usuario</Form.Label>
                                                    <Form.Control onChange={(e) => setConfiPassword(e.target.value)} type="password" placeholder="******" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicSelect">
                                                    <Form.Label className="text-center">Selecciona una rol</Form.Label>
                                                    <Form.Select
                                                    value={rol}
                                                    onChange={(e) => setRol(e.target.value)}
                                                    className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                    >
                                                        <option value="">Selecciona una opción...</option>
                                                        <option value="user">Usuario sin privilegios</option>
                                                        <option value="admin">Administrador</option>
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicVerReportes" className="mb-3">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Activar permiso para ver reportes mensuales"
                                                        checked={verReportes}
                                                        onChange={() => setVerReportes(!verReportes)}
                                                    />
                                                </Form.Group>

                                                <label>*Selecciona las empresas a las que tendrá acceso:</label>
                                                <div className='table-responsive mt-2 mb-2' style={{ maxHeight: "300px" }}>
                                                    <table className="table table-striped align-middle table-hover table-sm">
                                                        <tbody>
                                                        {empresas.map((empresa) => (
                                                            <React.Fragment key={empresa.uuid}>
                                                            <tr>
                                                                <td>{empresa.nombre}</td>
                                                                <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={permisosEmpresasSeleccionados[empresa.id] !== undefined}
                                                                    onChange={(e) => handlePermisoEmpresaChange(e, empresa.id)}
                                                                />
                                                                </td>
                                                            </tr>
                                                            {permisosEmpresasSeleccionados[empresa.id] !== undefined && (
                                                                empresa.sucursales.map((sucursal) => (
                                                                <tr key={sucursal.id}>
                                                                    <td colSpan={2} style={{ paddingLeft: '40px' }}>
                                                                    {sucursal.nombre}
                                                                    <input
                                                                        type="checkbox"
                                                                        value={sucursal.id}
                                                                        className='m-2'
                                                                        onChange={(e) => handlePermisoSucursalChange(e, empresa.id)}
                                                                        checked={permisosSucursalesSeleccionados[empresa.id]?.includes(sucursal.id) || false}
                                                                    />
                                                                    </td>
                                                                </tr>
                                                                ))
                                                            )}
                                                            </React.Fragment>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Actualizar
                                                            </Button>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>

        </div>
    )
}

export default FromEditUser