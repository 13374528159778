import React, {useEffect, useState} from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_edit_empresa from '../img/icono_edit_empresa.png'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const FormAddEmpresa = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [nombre, setNombre] = useState("");
    const [sucursales, setSucursales] = useState([]);
    const [addSucursales, setAddSucursales] = useState([]);

    const [editedSucursales, setEditedSucursales] = useState({});
    const [currentSucursales, setCurrentSucursales] = useState("");

    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();


    const getEmpresaById = async () => {
        try {
            const response = await axios.get(`https://api.seguroll-asistencias.com/empresas/${id}`);
            setNombre(response.data.nombre);
            setSucursales(response.data.sucursales);
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg);
            }
        }
    }

    useEffect(() => {        
        getEmpresaById();
    }, [id]);

    const agregarSucursal = () => {
        setAddSucursales([...addSucursales, '']);
    };
    
    const eliminarSucursal = (index) => {
        const nuevasSucursales = [...addSucursales];
        nuevasSucursales.splice(index, 1);
        setAddSucursales(nuevasSucursales);
    };
    
    const handleChangeSucursal = (index, value) => {
        const nuevasSucursales = [...addSucursales];
        nuevasSucursales[index] = value;
        setAddSucursales(nuevasSucursales);
    };

    const updateEmpresa = async(e) => {
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 

            if (result.isConfirmed) {

                try {
                    await axios.patch(`https://api.seguroll-asistencias.com/empresas/${id}`, {
                        nombre: nombre,
                        sucursales: addSucursales
                    })
                    navigate("/empresas");            
                } catch (error) {
                    if(error.response){
                        setMsg(error.response.data.msg);
                    }
                }

            }

        });   

    }

    const modificarSucursal = async(e) => {

        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 

            if (result.isConfirmed) {

                e.preventDefault();
                const sucursalesActualizadas = sucursales.map((sucursal) => {
                    if (editedSucursales.hasOwnProperty(sucursal.uuid)) {
                        return {
                            ...sucursal,
                            nombre: editedSucursales[sucursal.uuid]
                        };
                    }
                    return sucursal;
                });
                
                await axios.patch(`https://api.seguroll-asistencias.com/sucursalesMultiple/`, {
                    sucursales: sucursalesActualizadas
                })
                getEmpresaById();

            }

        });    
        
    }

    const borrarSucursal = async (sucursalId) => {

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {
                try {
         
                    await axios.delete(`https://api.seguroll-asistencias.com/sucursales/${sucursalId}`);
                    const nuevasSucursales = sucursales.filter((sucursal) => sucursal.uuid !== sucursalId);
                    setSucursales(nuevasSucursales);
                    getEmpresaById();
                    
                } catch (error) {
                    if(error){
                        console.log(error.message)
                    }
                }
            }      
        });        

    }

    return (
        <div>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Empresas</h1>
                        <h2 className='subtitle'>Editar empresa</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_edit_empresa} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>
                <Container style={{ maxHeight: "50%"}}>
                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={8} lg={10} xs={12}>
                            <Card className="shadow">                            
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-2 pt-0">*Todos los campos son obligatorios</p>
                                        <div className="mb-3">
                                            <Form onSubmit={updateEmpresa}>
                                                {msg &&
                                                    <div className="alert alert-danger" role="alert">
                                                        {msg}
                                                    </div>
                                                }                                                
                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                        <strong>Nombre:</strong>
                                                    </Form.Label>
                                                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Nombre de la empresa" />
                                                </Form.Group>

                                                <button type="button" className='btn btn-secondary mb-3' onClick={agregarSucursal}>Agregar Sucursal</button>
                                                <div>
                                                    {addSucursales.map((sucursal, index) => (
                                                    <div key={index}>
                                                        <label className='m-2' htmlFor={`sucursal-${index}`}>Sucursal {index + 1}:</label>
                                                        <input
                                                        type="text"
                                                        id={`sucursal-${index}`}
                                                        value={sucursal}
                                                        onChange={(e) => handleChangeSucursal(index, e.target.value)}
                                                        />
                                                        <button type="button" className="btn btn-light m-2" onClick={() => eliminarSucursal(index)}>Eliminar</button>
                                                    </div>
                                                    ))}
                                                </div>
                                                
                                                <div className="d-grid container mt-3">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Actualizar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container style={{ maxHeight: "50%"}}>
                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={8} lg={10} xs={12}>
                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        <p className=" mb-2 pt-0"><strong>Sucursales</strong></p>
                                        <div className="mb-3">
                                            <Form onSubmit={modificarSucursal}>

                                                {sucursales.map((sucursal, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            style={{ display: 'inline-block', width: '50%' }}
                                                            value={editedSucursales[sucursal.uuid] || sucursal.nombre}
                                                            onChange={(e) => setEditedSucursales({
                                                                ...editedSucursales,
                                                                [sucursal.uuid]: e.target.value
                                                            })}
                                                        />
                                                        {/*<button onClick={() => modificarSucursal(sucursal.uuid)} className='btn btn-secondary m-2'>Modificar</button>*/}
                                                        <button type="button" className="btn btn-light m-2" onClick={() => borrarSucursal(sucursal.uuid)}>Eliminar</button>
                                                    </div>
                                                ))}

                                                <div className="d-grid container mt-3">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Actualizar sucursales
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>                
            </div>
        </div>
    )
}

export default FormAddEmpresa