import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import icono_usuario from "../img/icono_usuario3.png"
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import DataTable from './DataTables';
import Swal from "sweetalert2";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";

const HistorialList = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [loading, setLoading] = useState(true);

    const [quejas, setQuejas] = useState([]);
    const [msg, setMsg] = useState("");
    const {user} = useSelector((state) => state.auth);
    const {userId} = useParams();
    const [msgQueja, setMsgQueja] = useState("");
    const [empresasQuejas, setEmpresasQuejas] = useState([]);

    const eliminarArchivo = (index) => {
        const nuevosArchivos = [...archivosQueja];
        nuevosArchivos.splice(index, 1);
        setArchivosQueja(nuevosArchivos);
    };

    const handleTelefonoChange = (e) => {
        const inputValue = e.target.value;
        
        // Verificar que solo sean números usando una expresión regular
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setTelefonoQueja(inputValue);
        }
    };

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '13%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [dataTable, setDataTable] = useState([]);

    const deleteQueja = async (quejaId) => {

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => { 
    
            if (result.isConfirmed) {
                await axios.delete(`https://api.seguroll-asistencias.com/quejas/${quejaId}`);
                getQuejas();
            }
    
        });

    }

    const [empresasFiles, setEmpresasFiles] = useState([]);
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        if (user && user.role === "admin") {            
            getEmpresas(); // Solo ejecutamos la petición si el usuario es admin
            //getPolizasDuplicadas();
            //getDataGrafica();
        } else {
            getEmpresasUser();// si el usuario no es admin, cargamos otras empresas
        }
      }, [user]); // Observamos cambios en 'user'

    // Consultamos todas las empresas disponibles en el caso del admin
    const getEmpresas = async () => {
        try {
            let response;
            if(user && user.role === 'admin'){
                response = await axios.get('https://api.seguroll-asistencias.com/empresas');
                setEmpresas(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    
    const getEmpresasUser = async () => {
        try {
            let response;
            if(user){
                response = await axios.get(`https://api.seguroll-asistencias.com/empresasUser/${user.uuid}`);
                let data = []
                const userEmpresas = response.data.usuarios_empresas;
                
                userEmpresas.forEach(empresa => {
                    data.push(empresa.empresa)
                });                
                setEmpresas(data);
                setEmpresasFiles(data);
                setEmpresasQuejas(data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    /*
    useEffect(() => {        
        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(empresaQueja !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursalesUser/${empresaQueja}`);                    
                    setSucursalesQuejas(response.data);

                    const empresaEncontrada = empresasFiles.find(item => item.id == empresaQueja);
                    if (empresaEncontrada) {
                        setNombreEmpresaQueja(empresaEncontrada.nombre);
                    } else {
                        setNombreEmpresaQueja("Sucursal...");
                    }

                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [empresaQueja])
    */

    const columns = useMemo(
        () => {
            const baseColumns = [
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Nombre completo</div>
                    ),
                    accessor: 'fullName'
                },
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Telefono</div>
                    ),
                    accessor: 'telefono',
                },
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Email</div>
                    ),
                    accessor: 'email',
                },
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center",
                        }}
                        >Estatus</div>
                    ),
                    accessor: "estatus.estatus",
                    Cell: ({ value, row }) => (
                        <div
                        style={{
                            color: row.original.estatus.color, // Aplica el color del estatus
                        }}
                        >
                        {value}
                        </div>
                    ),
                },
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Fecha de registro</div>
                    ),
                    accessor: 'createdAt',
                }
            ];

            if (user) {
                if (user.role === "admin") {
                    baseColumns.push({
                        Header: () => (
                            <div
                                style={{
                                    textAlign: "center"
                                }}
                            >Acciones</div>
                        ),
                        accessor: 'actions',
                        Cell: ({ row }) => (
                            <div>
                                <Link to={`/conversación/${row.original.uuid}`} className='btn btn-info m-1'>Revisar</Link>
                                <button onClick={() => deleteQueja(row.original.uuid)} className='btn btn-secondary'>Borrar</button>
                            </div>
                        ),
                    });
                } else {
                    baseColumns.push({
                        Header: () => (
                            <div
                                style={{
                                    textAlign: "center"
                                }}
                            >Acciones</div>
                        ),
                        accessor: 'actions',
                        Cell: ({ row }) => (
                            <div>
                                <Link to={`/conversación/${row.original.uuid}`} className='btn btn-info m-1'>Revisar</Link>
                            </div>
                        ),
                    });
                }
            }
        
          return baseColumns;
        },
        [user]
    );    

    useEffect(() => {
        if (user) {
            getQuejas();
        }
    }, [user]);


    const getQuejas = async () => {        
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/quejas`);
            setDataTable(response.data)
            setQuejas(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {            
            console.log(error.message);
        }
    }

    const [loadingQueja, setLoadingQueja] = useState(false);

    //Campos para la queja
    const [nombreQueja, setNombreQuejas] = useState("");
    const [telefonoQueja, setTelefonoQueja] = useState("");
    const [emailQueja, setEmailQueja] = useState("");
    const [descripcionQueja, setDescripcionQueja] = useState("");

    const [empresaNameFile, setEmpresaNameFiles] = useState("");

    const [empresaQueja, setEmpresaQueja] = useState("");
    const [sucursalSelectQueja, setSucursalSelectqueja] = useState("");
    const [sucursalesQueja, setSucursalesQuejas] = useState([]);
    const [nombreEmpresaQueja, setNombreEmpresaQueja] = useState("");

    const [errores, setErrores] = useState([])
    const [msgResponse, setMsgResponse] = useState("")

    const [polizaQueja, setPolizaQueja] = useState("");
    const [apellidoPQueja, setApellidoPQueja] = useState("");
    const [apellidoMQueja, setApellidoMQueja] = useState("");
    const [fechaIncidente, setFechaIncidente] = useState("");
    const [horaAproximada, setHoraAproximada] = useState("");

    const [archivosQueja, setArchivosQueja] = useState([]);

    useEffect(() => {        
        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(empresaQueja !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursalesUser/${empresaQueja}`);                    
                    setSucursalesQuejas(response.data);

                    const empresaEncontrada = empresasFiles.find(item => item.id == empresaQueja);
                    if (empresaEncontrada) {
                        setNombreEmpresaQueja(empresaEncontrada.nombre);
                    } else {
                        setNombreEmpresaQueja("Sucursal...");
                    }

                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [empresaQueja])

    const registroQueja = async(e) => {
        setLoadingQueja(true)
        e.preventDefault();

        let nuevosErrores = [];
        
        if (!nombreQueja || nombreQueja === '') {
            nuevosErrores.push('El nombre completo es obligatorio');
        }

        if (!apellidoPQueja || apellidoPQueja === '') {
            nuevosErrores.push('El apellido paterno es obligatorio');
        }

        if (!apellidoMQueja || apellidoMQueja === '') {
            nuevosErrores.push('El apellido materno es obligatorio');
        }
    
        if (!telefonoQueja || telefonoQueja === '') {
            nuevosErrores.push('El telefono es obligatorio');
        }
    
        if (!emailQueja || emailQueja === '') {
            nuevosErrores.push('El email es obligatorio');
        }
    
        if (!descripcionQueja || descripcionQueja === '') {
            nuevosErrores.push('Debes escribir la descripcion completa del problema, queja o sugerencia');
        }

        if(!empresaQueja || empresaQueja == ''){
            nuevosErrores.push('Debes seleccionar una empresa');
        }

        if(!fechaIncidente || fechaIncidente == ''){
            nuevosErrores.push('Debes indicar la fecha de los sucesos');
        }

        if(!horaAproximada || horaAproximada == ''){
            nuevosErrores.push('Debes indicar la hora aproximada');
        }

        let sucursalID;
        if(!sucursalSelectQueja){
            sucursalID = null
        }else{
            sucursalID = sucursalSelectQueja
        }

        const formData = new FormData();
        
        // Append each file separately
        for (let i = 0; i < archivosQueja.length; i++) {
            formData.append('files', archivosQueja[i]);
        }

        formData.append('poliza', polizaQueja);
        formData.append('nombreQueja', nombreQueja +' '+apellidoPQueja +' '+ apellidoMQueja);
        formData.append('telefonoQueja', telefonoQueja);
        formData.append('emailQueja', emailQueja);
        formData.append('descripcionQueja', descripcionQueja);
        formData.append('empresaQueja', empresaQueja);
        formData.append('sucursalSelectQueja', sucursalID);
        formData.append('fechaIncidente', fechaIncidente);
        formData.append('horaIncidente', horaAproximada);

        if (nuevosErrores.length > 0) {
            setErrores(nuevosErrores);
            setMsgQueja([]);
            
        }else{

            try {                        
                const response = await axios.post('https://api.seguroll-asistencias.com/quejas', formData);
                setMsgResponse(response.data.msg)
                setLoadingQueja(false);
            } catch (error) {
                if(error.response){
                    nuevosErrores.push('Ocurrio un error al enviar tu queja, intentalo de nuevo');
                    setErrores(nuevosErrores);
                    setMsgQueja([]);
                    setLoadingQueja(false);
                }
            }

        }

    }

    return (

        <div className='container-fluid'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Quejas</h1>
                        <h5 className='subtitle'>Lista de quejas</h5>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_usuario} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            {user && user.role !== "admin" ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <h5 className="mt-2 mb-4">REPORTAR UNA QUEJA DE ASISTENCIA</h5>
                        {errores.length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                <h3>Errores:</h3>
                                <ul>
                                    {errores.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}                        
                        <Row className="d-flex justify-content-center align-items-center mt-3 mb-3">
                            <Col md={8} lg={10} xs={12}>
                                <Card className="shadow">
                                    <Card.Body>
                                        <div className="mb-3 mt-0 md-4">
                                            <div className="alert alert-custom-yellow" role="alert">                                                
                                                <ul>
                                                    <li>Para registrar una queja, por favor completa el siguiente formulario con los datos del quejoso. Los campos marcados con un asterisco (*) son opcionales, mientras que los demás son obligatorios.</li>
                                                </ul>
                                                {/*<p><strong>Nota:</strong> Solo podrás descargar los archivos de las empresas a las que tienes acceso.</p>*/}
                                            </div>
                                            <div className="mb-3">
                                                <form onSubmit={registroQueja}>

                                                    <div className="container">

                                                        <div className="row">
                                                            <div className="col">

                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="nombreInput">* Poliza:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='No. Poliza'
                                                                        value={polizaQueja}
                                                                        onChange={(e) => setPolizaQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="nombreInput">Nombre:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='Nombre'
                                                                        value={nombreQueja}
                                                                        onChange={(e) => setNombreQuejas(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="nombreInput">Apellido paterno:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='Apellido paterno'
                                                                        value={apellidoPQueja}
                                                                        onChange={(e) => setApellidoPQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2" >
                                                                    <label htmlFor="nombreInput">Apellido materno:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='Apellido materno'
                                                                        value={apellidoMQueja}
                                                                        onChange={(e) => setApellidoMQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="apellidoMInput">Fecha de los sucesos:</label>
                                                                    <input
                                                                        type="date"
                                                                        id="apellidoMInput"
                                                                        placeholder="Fecha de los sucesos"
                                                                        value={fechaIncidente}
                                                                        onChange={(e) => setFechaIncidente(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="horaInput">Hora aproximada:</label>
                                                                    <input
                                                                        type="time"
                                                                        id="horaInput"
                                                                        value={horaAproximada}
                                                                        onChange={(e) => setHoraAproximada(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="telefonoInput">Teléfono con lada:</label>
                                                                    <input
                                                                        type="tel"
                                                                        id="telefonoInput"
                                                                        placeholder='Telefono de contacto'
                                                                        value={telefonoQueja}
                                                                        onChange={handleTelefonoChange} // Usar la función de manejo
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col'>

                                                                <Form.Group className="mb-3 mt-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                                    <Form.Select
                                                                        value={empresaQueja}
                                                                        onChange={(e) => setEmpresaQueja(e.target.value)}
                                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                                        style={{ height: "100%" }}
                                                                    >
                                                                        <option value="">Empresa...</option>
                                                                        {empresasQuejas.map((empresa) => (
                                                                            <option key={empresa.id} value={empresa.id}>{empresa.nombre}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                                <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                                    <Form.Select
                                                                        value={sucursalSelectQueja}
                                                                        onChange={(e) => setSucursalSelectqueja(e.target.value)}
                                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                                        style={{ height: "100%" }}
                                                                    >
                                                                        <option value="">
                                                                            {(!sucursalesQueja || sucursalesQueja.length === 0) ? (
                                                                                nombreEmpresaQueja || "Sucursal..."
                                                                            ) : "Sucursal..."}
                                                                        </option>
                                                                        {sucursalesQueja.map((sucursal) => (
                                                                            <option key={sucursal.sucursale.id} value={sucursal.sucursale.id}>{sucursal.sucursale.nombre}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="emailInput">Email:</label>
                                                                    <input
                                                                        type="email"
                                                                        id="emailInput"
                                                                        placeholder='Email de contacto'
                                                                        value={emailQueja}
                                                                        onChange={(e) => setEmailQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="descripcionInput">Descripción:</label>
                                                                    <textarea
                                                                        id="descripcionInput"
                                                                        placeholder='Descripcion del problema'
                                                                        value={descripcionQueja}
                                                                        onChange={(e) => setDescripcionQueja(e.target.value)}
                                                                        className="form-control"
                                                                        rows="3"
                                                                        style={{ height: "100%" }}
                                                                    ></textarea>
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="archivosInput">Archivos Adjuntos:</label>
                                                                    <input
                                                                        type="file"
                                                                        id="archivosInput"
                                                                        multiple
                                                                        onChange={(e) => setArchivosQueja(e.target.files)}
                                                                        className="form-control"
                                                                    />
                                                                    <div>
                                                                        {archivosQueja && archivosQueja.length > 0 && (
                                                                            <ul>
                                                                                {Array.from(archivosQueja).map((archivo, index) => (
                                                                                    <li key={index}>
                                                                                        {archivo.name}{" "}
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => eliminarArchivo(index)}
                                                                                            className="btn btn-link p-0"
                                                                                        >
                                                                                            Eliminar
                                                                                        </button>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center mt-5">
                                                            <div className="col text-center">
                                                                {loadingQueja ? 
                                                                    <p>Enviando formulario...</p>
                                                            : <Button className="btn btn-dark" variant="primary" type="submit">
                                                            Enviar
                                                        </Button>
                                                        }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {msgResponse && (
                            <div className="alert alert-success" role="alert">
                                <p>{msgResponse}</p>
                            </div>
                        )}

                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loading ? (
                        <p>Cargando datos...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                            ): (
                                <DataTable columns={columns} data={dataTable} />
                            )}
                        </>
                    )}
                </div>
            </div>

        </div>
    )

}

export default HistorialList