import React, { useEffect } from 'react'
import Layout from './Layout'
import FormAddAsegurado from '../components/FormAddAsegurado'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMe } from "../features/authSlice";

const EditUser = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isError, user} = useSelector((state => state.auth));

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  // Esto debemos validarlo porque se creara un perfil de usuario
  useEffect(() => {
    if(isError){
      navigate("/");
    }
    if(user && user.role !== 'admin'){
      navigate("/dashboard");
    }
  }, [isError, user, navigate]);

  return (
    <Layout>
        <FormAddAsegurado/>
    </Layout>
  )
}

export default EditUser