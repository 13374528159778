import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useFilters, usePagination, useGlobalFilter, useSortBy, useAsyncDebounce  } from 'react-table';

const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    const [value, setValue] = useState(filterValue);

    const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
    }, 1000);

    return (
        <input
            type="search"
            className="form-control form-control-sm d-inline-block"
            placeholder=""
            aria-controls="datatable"
            value={value || ""}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
        />
    );
};

export {ColumnFilter};