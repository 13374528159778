import React, { useState, useEffect } from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_add_user from '../img/icono_add_user.png'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const FromAddUser = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [verReportes, setVerReportes] = useState(false); // Nuevo estado para el checkbox

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    // Empresas a las que tendra acceso
    const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState({});
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        getEmpresas();
    }, []);
    
    const getEmpresas = async () => {
        try {
            const response = await axios.get('https://api.seguroll-asistencias.com/empresas');        
            setEmpresas(response.data);             
            setEmpresasSeleccionadas({}); // Inicializar el estado de empresas seleccionadas como un objeto vacío
        } catch (error) {
            console.log(error.message)
        }    
    }

    const handleEmpresaChange = (event, empresaId) => {
        const isChecked = event.target.checked;
        setEmpresasSeleccionadas(prevState => ({
            ...prevState,
            [empresaId]: isChecked ? [] : undefined // Si se selecciona una empresa, inicializar la lista de sucursales como vacía
        }));
    };

    const handleSucursalChange = (event, empresaId) => {
        const sucursalId = parseInt(event.target.value, 10);
        setEmpresasSeleccionadas(prevState => ({
            ...prevState,
            [empresaId]: prevState[empresaId].includes(sucursalId)
                ? prevState[empresaId].filter(id => id !== sucursalId)
                : [...prevState[empresaId], sucursalId]
        }));
    };

    const [poliza, setPoliza] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido_paterno, setApellidoPaterno] = useState("");
    const [apellido_materno, setApellidoMaterno] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confiPassword, setConfiPassword] = useState("");
    const [rol, setRol] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const saveUser = async(e) => {
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {

                if(!poliza || poliza === ''){
                    setMsg('El campo poliza es obligatorio');
                    return;
                }
        
                if(!nombre || nombre === ''){
                    setMsg('El campo nombre es obligatorio');
                    return;
                }
        
                if(!apellido_paterno || apellido_paterno === ''){
                    setMsg('El campo apellido paterno es obligatorio');
                    return;
                }
        
                if(!apellido_materno || apellido_materno === ''){
                    setMsg('El campo apellido materno es obligatorio');
                    return;
                }
        
                if(!email || email === ''){
                    setMsg('El campo email es obligatorio');
                    return;
                }
        
                if(!rol || rol === ''){
                    setMsg('Debes asignarle un rol al usuario');
                    return;
                }
        
                try {
                    await axios.post('https://api.seguroll-asistencias.com/users', {
                        poliza: poliza,
                        nombre: nombre,
                        apellido_paterno: apellido_paterno,
                        apellido_materno: apellido_materno,
                        email: email,
                        password: password,
                        confPassword: confiPassword,
                        rol: rol,
                        empresasSeleccionadas: empresasSeleccionadas,
                        verReportes: verReportes
                    });            
                    navigate("/users");
                } catch (error) {
                    if(error.response){                
                        setMsg(error.response.data.msg);
                    }
                }

            }
        });

    }

    return (
        <div className='mb-5'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Usuarios</h1>
                        <h2 className='subtitle'>Agregar un nuevo usuario</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_add_user} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>

                <Container style={{ maxHeight: "50%"}}>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={8} lg={10} xs={12}>
                            <Card className="shadow">                            
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">                                    
                                        <p className=" mb-5 pt-0">*Todos los campos son obligatorios para el registro de un nuevo usuario</p>
                                        <div className="mb-3">
                                            <Form onSubmit={saveUser}>
                                                {msg &&
                                                <div className="alert alert-danger" role="alert">
                                                    {msg}
                                                </div>
                                                }
                                                <Form.Group className="mb-3" controlId="formBasicPoliza">
                                                    <Form.Label className="text-center">
                                                    No. Poliza
                                                    </Form.Label>
                                                    <Form.Control value={poliza} onChange={(e) => setPoliza(e.target.value)} type="text" placeholder="Escribe tu numero de poliza" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                    Nombre
                                                    </Form.Label>
                                                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Escribe tu nombre" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoP">
                                                    <Form.Label className="text-center">
                                                    Apellido paterno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_paterno} onChange={(e) => setApellidoPaterno(e.target.value)} type="text" placeholder="Escribe tu apellido paterno" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoM">
                                                    <Form.Label className="text-center">
                                                    Apellido materno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_materno} onChange={(e) => setApellidoMaterno(e.target.value)} type="text" placeholder="Escribe tu apellido materno" />
                                                </Form.Group>                                            

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                    Correo Electronico
                                                    </Form.Label>
                                                    <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Escribe tu correo electronico" />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicPassword"
                                                >
                                                    <Form.Label>Contraseña para el usuario</Form.Label>
                                                    <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Contraseña" />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicConfigPassword"
                                                >
                                                    <Form.Label>Confirma la contraseña para el usuario</Form.Label>
                                                    <Form.Control value={confiPassword} onChange={(e) => setConfiPassword(e.target.value)} type="password" placeholder="Confirma tu contraseña" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicSelect">
                                                    <Form.Label className="text-center">Selecciona una rol</Form.Label>
                                                    <Form.Select
                                                        value={rol}
                                                        onChange={(e) => setRol(e.target.value)}
                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                    >
                                                        <option value="">Selecciona una opción...</option>
                                                        <option value="user">Usuario sin privilegios</option>
                                                        <option value="admin">Administrador</option>
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicVerReportes" className="mb-3">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Activar permiso para ver reportes mensuales"
                                                        checked={verReportes}
                                                        onChange={() => setVerReportes(!verReportes)}
                                                    />
                                                </Form.Group>

                                                <label>*Selecciona las empresas a las que tendrá acceso:</label>
                                                <div className='table-responsive mt-2 mb-2' style={{ maxHeight: "300px" }}>
                                                    <table className="table table-striped align-middle table-hover table-sm">
                                                        <tbody>
                                                            {empresas.map((empresa) => (
                                                                <React.Fragment key={empresa.uuid}>
                                                                    <tr>
                                                                        <td>{empresa.nombre}</td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={empresasSeleccionadas[empresa.id] !== undefined}
                                                                                onChange={(e) => handleEmpresaChange(e, empresa.id)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    {empresasSeleccionadas[empresa.id] !== undefined && (
                                                                        empresa.sucursales.map((sucursal) => (
                                                                            <tr key={sucursal.id}>
                                                                                <td colSpan={2} style={{ paddingLeft: '40px' }}>
                                                                                    {sucursal.nombre}
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={sucursal.id}
                                                                                        className='m-2'
                                                                                        onChange={(e) => handleSucursalChange(e, empresa.id)}
                                                                                        checked={empresasSeleccionadas[empresa.id].includes(sucursal.id)}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Registrar
                                                            </Button>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>

        </div>
    )
}

export default FromAddUser