import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./components/Home";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Empresas from "./pages/Empresas";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddEmpresa from "./pages/AddEmpresa";
import EditEmpresa from "./pages/EditEmpresa";
import Profile from "./pages/EditProfile";
import EditAsegurados from "./pages/EditAsegurados";
import RegistrosEmpresa from "./pages/RegistrosEmpresas";
import Asegurados from "./pages/Asegurados";
import Historial from "./pages/AseguradosHistorial";
import CargarArchivo from "./pages/CargaArchivo";
import AddAsegurado from "./pages/AddAsegurado";
import HistorialGeneral from "./pages/HistorialGeneral";
import EncargadosEmpresa from "./pages/EncargadosEmpresa";
import Quejas from "./pages/QuejasList";
import Conversación from "./pages/Conversacion";
import Media from "./pages/Media";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/*<Route path="/" element={<Home/>}/>*/}
          <Route path="/" element={<Login/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/users" element={<Users/>}/>
          <Route path="/users/add" element={<AddUser/>}/>
          <Route path="/users/edit/:id" element={<EditUser/>}/>
          <Route path="/users/profile/:id" element={<Profile/>}/>
          <Route path="/empresas" element={<Empresas/>}/>
          <Route path="/empresas/add" element={<AddEmpresa/>}/>
          <Route path="/empresas/edit/:id" element={<EditEmpresa/>}/>          
          <Route path="/empresas/registros/:empresaId" element={<RegistrosEmpresa/>}/>
          <Route path="/empresas/encargados/:empresaId" element={<EncargadosEmpresa/>}/>          
          <Route path="/asegurados" element={<Asegurados/>}/>
          <Route path="/asegurados/edit/:id" element={<EditAsegurados/>}/>
          <Route path="/historial/:poliza" element={<Historial/>}/>
          <Route path="/carga-archivo" element={<CargarArchivo/>}/>
          <Route path="/asegurados/add" element={<AddAsegurado/>}/>
          <Route path="/historial-general/:empresaId" element={<HistorialGeneral/>}/>
          <Route path="/quejas/:userId" element={<Quejas/>}/>
          <Route path="/conversación/:quejaId" element={<Conversación/>}/>
          <Route path="/media" element={<Media/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
