import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_dashboard from '../img/icono_dashboard.png'
import { useMemo } from 'react';
import DataTable from './DataTables';
import Swal from 'sweetalert2';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';
import Graph from './Graph';
import './table.css'
//import GraphVis from "./Graph-vis"

const Welcom = () => {    
    
    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    
    const [empresas, setEmpresas] = useState([]);
    const [reportesGlobales, setReportesGlobales] = useState([]);
    const [reportes, setReportes] = useState([]);
    const [polizasDuplicadas, setPolizasDuplicadas] = useState([]);
    const [aseguradoCheck, setAseguradoCheck] = useState([]);
    const [loadingFile, setLoadingFile] = useState(false);

    const [loadingQueja, setLoadingQueja] = useState(false);

    const [msg, setMsg] = useState("");
    const [msgQueja, setMsgQueja] = useState("");

    const {user} = useSelector((state) => state.auth);

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '12%' });
        }
    };

    useEffect(() => {          
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    useEffect(() => {
        if (user && user.role === "admin") {            
            getEmpresas(); // Solo ejecutamos la petición si el usuario es admin
            getPolizasDuplicadas();
            getReporteGlobal();
            getReportes();
            //getDataGrafica();
        } else {
            getEmpresasUser();// si el usuario no es admin, cargamos otras empresas
            getReportes();
        }
      }, [user]); // Observamos cambios en 'user'

    useEffect(() => {
        getReportes();
    }, []);

    const getReportes = async () => {
        try {
            let response;
            if(user && user.uuid){
                response = await axios.get(`https://api.seguroll-asistencias.com/reportesUser/${user.uuid}`);
                setReportes(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const columnsReportes = useMemo(
        () => {
          const baseColumns = [
            // ... (tus columnas existentes)
            {
              Header: 'Fecha',
              accessor: 'fecha',
            },
            {
              Header: 'Título',
              accessor: 'titulo',
            },
            {
              Header: 'Empresa',
              accessor: 'empresa.nombre',
            },
            {
              Header: 'Descargar',
              accessor: 'descargar',
              Cell: ({ row }) => (
                <a href={row.original.url} target="_blank" rel="noopener noreferrer" className='btn btn-primary m-1'>
                  Descargar
                </a>
              ),
            },
          ];
      
          return baseColumns;
        },
        [user]
      );

    // Consultamos todas las empresas disponibles en el caso del admin
    const getEmpresas = async () => {
        try {
            let response;
            if(user && user.role === 'admin'){
                response = await axios.get('https://api.seguroll-asistencias.com/empresas');
                setEmpresas(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const getReporteGlobal = async () => {
        try {
            let response;
            if(user && user.role === 'admin'){
                response = await axios.get('https://api.seguroll-asistencias.com/reportesGlobales');
                setReportesGlobales(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const [dataFromBackend, setDataFromBackend] = useState(null);
    const getDataGrafica = async () => {
        try {            
            if(user && user.role === 'admin'){
                const response = await axios.get('https://api.seguroll-asistencias.com/dataGrafica');
                console.log(response.data)
                setDataFromBackend(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const getPolizasDuplicadas = async () => {
        
        try {            
            let response;
            if(user && user.role === 'admin'){
                response = await axios.get('https://api.seguroll-asistencias.com/consultaDuplicados');
                setDataTableDouble(response.data)
                setPolizasDuplicadas(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

        
    //const [sucursales, setSucursales] = useState([]);

    const [empresasFiles, setEmpresasFiles] = useState([]);
    const [empresasQuejas, setEmpresasQuejas] = useState([]);
    
    const getEmpresasUser = async () => {
        try {
            let response;
            if(user){
                response = await axios.get(`https://api.seguroll-asistencias.com/empresasUser/${user.uuid}`);
                let data = []
                const userEmpresas = response.data.usuarios_empresas;
                
                userEmpresas.forEach(empresa => {
                    data.push(empresa.empresa)
                });                
                setEmpresas(data);
                setEmpresasFiles(data);
                setEmpresasQuejas(data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const [poliza, setPoliza] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido_paterno, setApellidoPaterno] = useState("");
    const [apellido_materno, setApellidoMaterno] = useState("");    

    // Sucursales para la descarga del archivo
    const [sucursales, setSucursales] = useState([]);
    const [empresaSelectFiles, setEmpresaSelectFiles] = useState("");
    const [sucursalSelectFiles, setSucursalSelectFiles] = useState("");

    //Campos para la queja
    const [nombreQueja, setNombreQuejas] = useState("");
    const [telefonoQueja, setTelefonoQueja] = useState("");
    const [emailQueja, setEmailQueja] = useState("");
    const [descripcionQueja, setDescripcionQueja] = useState("");

    const [empresaNameFile, setEmpresaNameFiles] = useState("");

    const [empresaQueja, setEmpresaQueja] = useState("");
    const [sucursalSelectQueja, setSucursalSelectqueja] = useState("");
    const [sucursalesQueja, setSucursalesQuejas] = useState([]);
    const [nombreEmpresaQueja, setNombreEmpresaQueja] = useState("");

    useEffect(() => {        
        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(empresaQueja !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursalesUser/${empresaQueja}`);                    
                    setSucursalesQuejas(response.data);

                    const empresaEncontrada = empresasFiles.find(item => item.id == empresaQueja);
                    if (empresaEncontrada) {
                        setNombreEmpresaQueja(empresaEncontrada.nombre);
                    } else {
                        setNombreEmpresaQueja("Sucursal...");
                    }

                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [empresaQueja])

    useEffect(() => {        
        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(empresaSelectFiles !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursalesUser/${empresaSelectFiles}`);                    
                    setSucursales(response.data);

                    const empresaEncontrada = empresasFiles.find(item => item.id == empresaSelectFiles);
                    if (empresaEncontrada) {
                        setEmpresaNameFiles(empresaEncontrada.nombre);
                    } else {
                        setEmpresaNameFiles("Sucursal...");
                    }

                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [empresaSelectFiles])

    // Sucursales para la busqueda del usuario
    const [empresa, setEmpresa] = useState("");    
    const [sucursalSearch, setSucursalSearch] = useState([]);
    const [sucursalCurrent, setSucursalCurrent] = useState("");

    const [empresaName, setEmpresaName] = useState("");

    useEffect(() => {
        const getSucursalesByEmpresa = async () => {
            let response;
            try {
                if(empresa !== ''){
                    response = await axios.get(`https://api.seguroll-asistencias.com/sucursalesUser/${empresa}`);                    
                    setSucursalSearch(response.data);
            
                    const empresaEncontrada = empresas.find(item => item.id == empresa);
                    if (empresaEncontrada) {
                        setEmpresaName(empresaEncontrada.nombre);
                    } else {
                        setEmpresaName("Selecciona una sucursal...");
                    }
                }
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getSucursalesByEmpresa();
    }, [empresa])

    const [dataTable, setDataTable] = useState([]);
    const [dataTableDouble, setDataTableDouble] = useState([]);

    const columns = useMemo(
        () => {
          const baseColumns = [
            {
                Header: 'Poliza',
                accessor: 'poliza',
                Cell: ({ row }) => (
                  <>
                    {user && user.role === "admin" ? (
                      <Link to={`/historial/${row.original.poliza}`} className='btn btn-outline-dark m-1'>
                        {row.original.poliza}
                      </Link>
                    ) : (
                      <span>{row.original.poliza}</span>
                    )}
                  </>
                ),
              },
              {
                Header: 'Empresa',
                accessor: 'empresa.nombre',
                Cell: ({ row }) => (
                  <>
                  {user && user.role === "admin" ? (
                    <div>
                      <Link to={`/empresas/registros/${row.original.empresa.uuid}`} className='btn btn-light m-1'>{row.original.empresa.nombre}</Link>
                    </div>
                  ) : (
                    <span>{row.original.empresa.nombre}</span>
                  )}
                  </>
                )
              },
              {
                Header: 'Sucursal',
                accessor: 'sucursal.nombre',
              },
              {
                Header: 'Nombre',
                accessor: 'nombre',
              },
              {
                Header: 'Apellido paterno',
                accessor: 'apellido_paterno',
              },
              {
                Header: 'Apellido materno',
                accessor: 'apellido_materno',
              },
              {
                Header: 'RFC',
                accessor: 'rfc',
              },
              {
                Header: 'F. nacimiento',
                accessor: 'fecha_nacimiento',
              },
              {
                Header: 'Inicio vigencia',
                accessor: 'inicio_vigencia',
              },
              {
                Header: 'Fin vigencia',
                accessor: 'fin_vigencia',
              }
          ];
    
          if (user && user.role === "admin") {
            baseColumns.push({
              Header: 'Acciones',
              accessor: 'actions',
              Cell: ({ row }) => (
                <div>
                  <Link to={`/asegurados/edit/${row.original.uuid}`} className='btn btn-info m-1'>Editar</Link>
                  <button onClick={() => deleteAfiliado(row.original.uuid)} className='btn btn-secondary'>Borrar</button>
                </div>
              ),
            });
          }
    
          return baseColumns;
        },
        [user]
    );

    const [loagindCheck, setLoadingCheck] = useState(false);

    // Consultamos un asegurado en especifico:
    const checkAsegurado = async(e) => {
        e.preventDefault();
        setLoadingCheck(true);
        if(!empresa || empresa == ''){
            setMsg('Debes seleccionar una empresa para buscar un registro');
            return
        }

        try {
            
            if (poliza || nombre || apellido_paterno || apellido_materno) {
                const response = await axios.post('https://api.seguroll-asistencias.com/aseguradosCheck', {
                    poliza: poliza,
                    nombre: nombre,
                    apellido_paterno: apellido_paterno,
                    apellido_materno: apellido_materno,
                    empresa_asegurados: empresa,
                    sucursalCurrent: sucursalCurrent
                });

                if(response.data.length === 0){                
                    setMsg('No se encontro ningun registro');
                    setAseguradoCheck([]);                
                }else{
                    setDataTable(response.data)
                    setAseguradoCheck(response.data);
                    setMsg('');
                }    
                setLoadingCheck(false);

            }else{
                setMsg('Debes ingresar al menos un dato para filtrar.');
            }

        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg);
                setLoadingCheck(false);
            }
        }
    }

    const descargarExcel = async(e) => {
        e.preventDefault();
        
        let response;
        try {
            setLoadingFile(true);
            response = await axios.get(`https://api.seguroll-asistencias.com/dowloadFileUser`, {
                params: {
                  empresaId: empresaSelectFiles,
                  sucursalId: sucursalSelectFiles,
                },
                responseType: 'arraybuffer',
            });
            
            // Crear un blob a partir de los datos binarios recibidos en la respuesta
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear un objeto URL para el blob
            const url = window.URL.createObjectURL(blob);

            // Crear un enlace de descarga y hacer clic en él para iniciar la descarga del archivo Excel
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx');
            document.body.appendChild(link);
            link.click();

            // Liberar el objeto URL
            window.URL.revokeObjectURL(url);
            setLoadingFile(false);
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg);
            }
        }
    }

    const [errores, setErrores] = useState([])
    const [msgResponse, setMsgResponse] = useState("")

    const [polizaQueja, setPolizaQueja] = useState("");
    const [apellidoPQueja, setApellidoPQueja] = useState("");
    const [apellidoMQueja, setApellidoMQueja] = useState("");
    const [fechaIncidente, setFechaIncidente] = useState("");
    const [horaAproximada, setHoraAproximada] = useState("");

    const [archivosQueja, setArchivosQueja] = useState([]);

    const eliminarArchivo = (index) => {
        const nuevosArchivos = [...archivosQueja];
        nuevosArchivos.splice(index, 1);
        setArchivosQueja(nuevosArchivos);
    };

    const registroQueja = async(e) => {
        setLoadingQueja(true)
        e.preventDefault();

        let nuevosErrores = [];
        
        if (!nombreQueja || nombreQueja === '') {
            nuevosErrores.push('El nombre completo es obligatorio');
        }

        if (!apellidoPQueja || apellidoPQueja === '') {
            nuevosErrores.push('El apellido paterno es obligatorio');
        }

        if (!apellidoMQueja || apellidoMQueja === '') {
            nuevosErrores.push('El apellido materno es obligatorio');
        }
    
        if (!telefonoQueja || telefonoQueja === '') {
            nuevosErrores.push('El telefono es obligatorio');
        }
    
        if (!emailQueja || emailQueja === '') {
            nuevosErrores.push('El email es obligatorio');
        }
    
        if (!descripcionQueja || descripcionQueja === '') {
            nuevosErrores.push('Debes escribir la descripcion completa del problema, queja o sugerencia');
        }

        if(!empresaQueja || empresaQueja == ''){
            nuevosErrores.push('Debes seleccionar una empresa');
        }

        if(!fechaIncidente || fechaIncidente == ''){
            nuevosErrores.push('Debes indicar la fecha de los sucesos');
        }

        if(!horaAproximada || horaAproximada == ''){
            nuevosErrores.push('Debes indicar la hora aproximada');
        }

        let sucursalID;
        if(!sucursalSelectQueja){
            sucursalID = null
        }else{
            sucursalID = sucursalSelectQueja
        }

        const formData = new FormData();
        
        // Append each file separately
        for (let i = 0; i < archivosQueja.length; i++) {
            formData.append('files', archivosQueja[i]);
        }

        formData.append('poliza', polizaQueja);
        formData.append('nombreQueja', nombreQueja +' '+apellidoPQueja +' '+ apellidoMQueja);
        formData.append('telefonoQueja', telefonoQueja);
        formData.append('emailQueja', emailQueja);
        formData.append('descripcionQueja', descripcionQueja);
        formData.append('empresaQueja', empresaQueja);
        formData.append('sucursalSelectQueja', sucursalID);
        formData.append('fechaIncidente', fechaIncidente);
        formData.append('horaIncidente', horaAproximada);

        if (nuevosErrores.length > 0) {
            setErrores(nuevosErrores);
            setMsgQueja([]);
            
        }else{

            try {                        
                const response = await axios.post('https://api.seguroll-asistencias.com/quejas', formData);
                setMsgResponse(response.data.msg)
                setLoadingQueja(false);
            } catch (error) {
                if(error.response){
                    nuevosErrores.push('Ocurrio un error al enviar tu queja, intentalo de nuevo');
                    setErrores(nuevosErrores);
                    setMsgQueja([]);
                    setLoadingQueja(false);
                }
            }

        }

    }

    // Eliminamos a los usuarios
    const deleteAfiliado = async (aseguradoId) => {

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            // Si el usuario hizo clic en el botón "Sí, estoy seguro"
            if (result.isConfirmed) {
              
                try {
                    await axios.delete(`https://api.seguroll-asistencias.com/asegurados/${aseguradoId}`);
        
                    setDataTable((dataTable) => dataTable.filter((item) => {                
                        return item.uuid !== aseguradoId;
                    }));

                    setDataTableDouble((dataDouble) => dataDouble.filter((item) => {                
                        return item.uuid !== aseguradoId;
                    }));
        
                } catch (error) {
                    console.log(error);
                    if(error.response){
                        setMsg(error.response.data.msg);
                    }
                }

            }
          });
        
    }

    const handleTelefonoChange = (e) => {
        const inputValue = e.target.value;
        
        // Verificar que solo sean números usando una expresión regular
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setTelefonoQueja(inputValue);
        }
    };

    const spinnerStyle = css`
        display: block;
        margin: 0 auto;
    `;

    return (    
        <div className='container-fluid'>
        
            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        {/*<h1 className='title'>Panel de inicio</h1>*/}
                        <h1 className='subtitle'>Bienvenido <strong>{user && user.nombre}</strong></h1>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_dashboard} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            {user && user.role === "admin" ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <div>
                            <h5 className='mt-2 mb-4'>REPORTE GLOBAL</h5>
                            {/* Imprime el primer elemento aquí */}
                            {reportesGlobales.length > 0 && (
                            <div>                                
                                <p>Título: {reportesGlobales[0].titulo}</p>
                                <p>Fecha: {reportesGlobales[0].fecha}</p>
                                <a href={reportesGlobales[0].url} download={reportesGlobales[0].titulo}>
                                    Descargar Archivo
                                </a>
                                {/* Agrega más campos según sea necesario */}
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {user && user.uuid ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <h5 className="mt-2 mb-4">REPORTES POR EMPRESAS</h5>
                        <div className='table-responsive mt-5' style={{ maxHeight: "600px" }}>
                            {reportes.length === 0 ? (
                                <p>Sin información disponible.</p>
                            ) : (
                            <>
                                {reportes.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                                ) : (
                                    <DataTable columns={columnsReportes} data={reportes} />
                                )}
                            </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className="card mb-5">                
                <div className="card-body">
                    <h5 className='mt-2 mb-4'>BÚSQUEDA INDIVIDUAL DE BENEFICIARIOS</h5>
                    <div className="alert alert-primary" role="alert">
                        <h6><strong>¿CÓMO PUEDO BUSCAR A UN BENEFICIARIO?</strong></h6>
                        <ol>
                            <li>Para llevar a cabo una <strong>BUSQUEDA INDIVIDUAL</strong> de un beneficiario, selecciona una empresa y sucursal. Es importante tener en cuenta que al menos debes completar el Nombre, Apellido o número de Póliza para realizar las búsqueda. Mientras más campos del filtro completes, mejorará tu búsqueda.</li>
                            <li>Si deseas realizar una <strong>BÚSQUEDA GLOBAL</strong>  para localizar a un beneficiario en todas las empresas, dirígete al MENÚ DE OPCIONES, y selecciona el apartado PÓLIZAS.  Ahi podrás completar los filtros para localizar a los usuarios entre la totalidad de las empresas a las cuales tienes permitido el acceso.</li>
                        </ol>                        
                    </div>
                    {msg &&
                        <div className="alert alert-danger" role="alert">
                            {msg}
                        </div>
                    }
                    <div className="container d-flex m-0">
                        <form onSubmit={checkAsegurado}>
                            <div className='row'>
                            <div className='col-lg-2 col-md-4 col-sm-6 mb-2'>
                                    <Form.Group className="" style={{ height: "48px" }} controlId="formBasicSelect">
                                        <Form.Select
                                            value={empresa}
                                            onChange={(e) => setEmpresa(e.target.value)}
                                            className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                            style={{ height: "100%" }}
                                        >
                                            <option value="">Empresa...</option>
                                            {empresas.map((empresa) => {                                                
                                                return (
                                                    <option key={empresa.id} value={empresa.id}>{empresa.nombre}</option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 mb-2'>
                                    <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                        <Form.Select
                                            value={sucursalCurrent}
                                            onChange={(e) => setSucursalCurrent(e.target.value)}
                                            className="form-select"
                                            style={{ height: "100%" }}
                                        >
                                            <option value="">
                                                {(!sucursalSearch || sucursalSearch.length === 0) ? (
                                                    empresaName || "Selecciona una sucursal..."
                                                ) : "Seleccione una opción..."}
                                            </option>
                                            {user && user.role !== "admin" ? (
                                                sucursalSearch.map((sucursal) => (
                                                    <option key={sucursal.sucursale.id} value={sucursal.sucursale.id}>{sucursal.sucursale.nombre}</option>
                                                ))
                                            ) : (
                                                sucursalSearch.map((sucursal) => (
                                                    <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>
                                                ))
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 mb-2'>
                                    <input className="form-control form-control-lg" value={poliza} onChange={(e) => setPoliza(e.target.value)} type="text" placeholder="No. de poliza" aria-label="No. de poliza example"/>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 mb-2'>
                                    <input className="form-control form-control-lg" value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Nombre" aria-label="Nombre example"/>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 mb-2'>
                                    <input className="form-control form-control-lg" value={apellido_paterno} onChange={(e) => setApellidoPaterno(e.target.value)} type="text" placeholder="Apellido paterno" aria-label="Apellido paterno example"/>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 mb-2'>
                                    <input className="form-control form-control-lg" value={apellido_materno} onChange={(e) => setApellidoMaterno(e.target.value)} type="text" placeholder="Apellido materno" aria-label="Apellido materno example"/>
                                </div>                                
                                <div className='col-lg-3 col-md-4 col-sm-6 d-grid mb-2'>
                                    <button type="submit" className="btn btn-dark btn-lg">Buscar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='table-responsive mt-5' style={{ maxHeight: "600px" }}>
                        {loagindCheck ? (
                            <p>Cargando datos...</p>
                        ) : (
                        <>
                            {dataTable.length === 0 ? (
                            <p>Sin información disponible.</p>
                            ) : (
                            <>
                                {dataTable.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                                ) : (
                                    <DataTable columns={columns} data={dataTable} />
                                )}
                            </>
                            )}
                        </>
                        )}
                    </div>
                </div>
            </div>

            {user && user.role === "admin" ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <div>
                            <h5 className='mt-2 mb-4'>GRÁFICO DE ASEGURADOS POR EMPRESA Y SUCURSAL</h5>
                            {dataFromBackend && Object.keys(dataFromBackend).length !== 0 ? (                                
                                <Graph />
                            ):(
                                <div>Loading...</div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {user && user.role === "admin" ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <h5 className="mt-2 mb-4">REGISTROS DUPLICADOS EXISTENTES</h5>
                        <div className='table-responsive mt-5' style={{ maxHeight: "600px" }}>
                            {dataTableDouble.length === 0 ? (
                                <p>Sin información disponible.</p>
                            ) : (
                            <>
                                {dataTableDouble.some((element) => element === undefined) ? (
                                <p>Cargando datos...</p>
                                ) : (
                                    <DataTable columns={columns} data={dataTableDouble} />
                                )}
                            </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {user && user.role !== "admin" ? (
                <div className="card mb-5">

                    <Row className="d-flex justify-content-center align-items-center mt-3 mb-3">
                        <Col md={8} lg={11} xs={12}>
                            <div className="alert alert-custom-purple" role="alert">
                                <h5><strong>DESCARGA DE LISTA DE PÓLIZAS</strong></h5>
                                <ol>
                                    <li>Elige la empresa de la que deseas descargar el archivo de Excel y, si lo deseas, puedes también indicar una sucursal específica.</li>                                    
                                </ol>
                                {/*<p><strong>Nota:</strong> Solo podrás descargar los archivos de las empresas a las que tienes acceso.</p>*/}
                            </div>

                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">
                                        {/*<p className=" mb-2 pt-0"><strong>Por favor, selecciona el archivo, la empresa y, en caso de tener una, la sucursal para cargar tu archivo.</strong></p>*/}
                                        <div className="mb-3">
                                            <form onSubmit={descargarExcel}>
                                                {/*msg &&
                                                    <div className="alert alert-success" role="alert">
                                                        {msg}
                                                    </div>
                                                    */}
                                                {loadingFile ? (
                                                    <div className="loading-container">
                                                        <RingLoader color={'#123abc'} loading={true} css={spinnerStyle} size={50} />
                                                        <p>Generando archivo...</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {msg &&
                                                            <div className="alert alert-success" role="alert">
                                                                <p>Archivo generado con éxito</p>
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                                
                                                <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                    <Form.Select
                                                        value={empresaSelectFiles}
                                                        onChange={(e) => setEmpresaSelectFiles(e.target.value)}
                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                        style={{ height: "100%" }}
                                                    >
                                                        <option value="">Empresa...</option>
                                                        {empresasFiles.map((empresa) => (
                                                            <option key={empresa.id} value={empresa.id}>{empresa.nombre}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                    <Form.Select
                                                        value={sucursalSelectFiles}
                                                        onChange={(e) => setSucursalSelectFiles(e.target.value)}
                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                        style={{ height: "100%" }}
                                                    >
                                                        <option value="">
                                                            {(!sucursales || sucursales.length === 0) ? (
                                                                empresaNameFile || "Sucursal..."
                                                            ) : "Sucursal..."}
                                                        </option>
                                                        {sucursales.map((sucursal) => (
                                                            <option key={sucursal.sucursale.id} value={sucursal.sucursale.id}>{sucursal.sucursale.nombre}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Descargar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                    </Row>
                </div>
            ) : (
                <></>
            )}

            {/*user && user.role !== "admin" ? (
                <div className="card mb-5">
                    <div className="card-body">
                        <h5 className="mt-2 mb-4">REPORTAR UNA QUEJA DE ASISTENCIA</h5>
                        {errores.length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                <h3>Errores:</h3>
                                <ul>
                                    {errores.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}                        
                        <Row className="d-flex justify-content-center align-items-center mt-3 mb-3">
                            <Col md={8} lg={10} xs={12}>
                                <Card className="shadow">
                                    <Card.Body>
                                        <div className="mb-3 mt-0 md-4">
                                            <div className="alert alert-custom-yellow" role="alert">                                                
                                                <ul>
                                                    <li>Para registrar una queja, por favor completa el siguiente formulario con los datos del quejoso. Los campos marcados con un asterisco (*) son opcionales, mientras que los demás son obligatorios.</li>
                                                </ul>
                                            </div>
                                            <div className="mb-3">
                                                <form onSubmit={registroQueja}>

                                                    <div className="container">

                                                        <div className="row">
                                                            <div className="col">

                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="nombreInput">* Poliza:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='No. Poliza'
                                                                        value={polizaQueja}
                                                                        onChange={(e) => setPolizaQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="nombreInput">Nombre:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='Nombre'
                                                                        value={nombreQueja}
                                                                        onChange={(e) => setNombreQuejas(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="nombreInput">Apellido paterno:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='Apellido paterno'
                                                                        value={apellidoPQueja}
                                                                        onChange={(e) => setApellidoPQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2" >
                                                                    <label htmlFor="nombreInput">Apellido materno:</label>
                                                                    <input
                                                                        type="text"
                                                                        id="nombreInput"
                                                                        placeholder='Apellido materno'
                                                                        value={apellidoMQueja}
                                                                        onChange={(e) => setApellidoMQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="apellidoMInput">Fecha de los sucesos:</label>
                                                                    <input
                                                                        type="date"
                                                                        id="apellidoMInput"
                                                                        placeholder="Fecha de los sucesos"
                                                                        value={fechaIncidente}
                                                                        onChange={(e) => setFechaIncidente(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="horaInput">Hora aproximada:</label>
                                                                    <input
                                                                        type="time"
                                                                        id="horaInput"
                                                                        value={horaAproximada}
                                                                        onChange={(e) => setHoraAproximada(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-2">
                                                                    <label htmlFor="telefonoInput">Teléfono con lada:</label>
                                                                    <input
                                                                        type="tel"
                                                                        id="telefonoInput"
                                                                        placeholder='Telefono de contacto'
                                                                        value={telefonoQueja}
                                                                        onChange={handleTelefonoChange} // Usar la función de manejo
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col'>

                                                                <Form.Group className="mb-3 mt-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                                    <Form.Select
                                                                        value={empresaQueja}
                                                                        onChange={(e) => setEmpresaQueja(e.target.value)}
                                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                                        style={{ height: "100%" }}
                                                                    >
                                                                        <option value="">Empresa...</option>
                                                                        {empresasQuejas.map((empresa) => (
                                                                            <option key={empresa.id} value={empresa.id}>{empresa.nombre}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                                <Form.Group className="mb-3" style={{ height: "48px" }} controlId="formBasicSelect">
                                                                    <Form.Select
                                                                        value={sucursalSelectQueja}
                                                                        onChange={(e) => setSucursalSelectqueja(e.target.value)}
                                                                        className="form-select" // Aplica la clase "form-select" para darle estilo de Bootstrap
                                                                        style={{ height: "100%" }}
                                                                    >
                                                                        <option value="">
                                                                            {(!sucursalesQueja || sucursalesQueja.length === 0) ? (
                                                                                nombreEmpresaQueja || "Sucursal..."
                                                                            ) : "Sucursal..."}
                                                                        </option>
                                                                        {sucursalesQueja.map((sucursal) => (
                                                                            <option key={sucursal.sucursale.id} value={sucursal.sucursale.id}>{sucursal.sucursale.nombre}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="emailInput">Email:</label>
                                                                    <input
                                                                        type="email"
                                                                        id="emailInput"
                                                                        placeholder='Email de contacto'
                                                                        value={emailQueja}
                                                                        onChange={(e) => setEmailQueja(e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: "100%" }}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="descripcionInput">Descripción:</label>
                                                                    <textarea
                                                                        id="descripcionInput"
                                                                        placeholder='Descripcion del problema'
                                                                        value={descripcionQueja}
                                                                        onChange={(e) => setDescripcionQueja(e.target.value)}
                                                                        className="form-control"
                                                                        rows="3"
                                                                        style={{ height: "100%" }}
                                                                    ></textarea>
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <Form.Group className="mt-1">
                                                                    <label htmlFor="archivosInput">Archivos Adjuntos:</label>
                                                                    <input
                                                                        type="file"
                                                                        id="archivosInput"
                                                                        multiple
                                                                        onChange={(e) => setArchivosQueja(e.target.files)}
                                                                        className="form-control"
                                                                    />
                                                                    <div>
                                                                        {archivosQueja && archivosQueja.length > 0 && (
                                                                            <ul>
                                                                                {Array.from(archivosQueja).map((archivo, index) => (
                                                                                    <li key={index}>
                                                                                        {archivo.name}{" "}
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => eliminarArchivo(index)}
                                                                                            className="btn btn-link p-0"
                                                                                        >
                                                                                            Eliminar
                                                                                        </button>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center mt-5">
                                                            <div className="col text-center">
                                                                {loadingQueja ? 
                                                                    <p>Enviando formulario...</p>
                                                            : <Button className="btn btn-dark" variant="primary" type="submit">
                                                            Enviar
                                                        </Button>
                                                        }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {msgResponse && (
                            <div className="alert alert-success" role="alert">
                                <p>{msgResponse}</p>
                            </div>
                        )}

                    </div>
                </div>
            ) : (
                <></>
            )*/}


        </div>
    )
}

export default Welcom
