import React, {useEffect, useState} from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import icono_edi_user from '../img/icono_edi_user.png'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

const FromProfileUser = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [poliza, setPoliza] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido_paterno, setApellidoPaterno] = useState("");
    const [apellido_materno, setApellidoMaterno] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confiPassword, setConfiPassword] = useState("");
    const [rol, setRol] = useState("");

    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        const getUserbyId = async () => {
            try {
                const response = await axios.get(`https://api.seguroll-asistencias.com/profiles/${id}`);
                setPoliza(response.data.poliza);
                setNombre(response.data.nombre);
                setApellidoPaterno(response.data.apellido_paterno);
                setApellidoMaterno(response.data.apellido_materno);
                setEmail(response.data.email);
                setRol(response.data.role);                
                
            } catch (error) {
                if(error.response){
                    setMsg(error.response.data.msg);
                }
            }
        }
        getUserbyId();
    }, [id]);

    const updateUser = async(e) => {
        e.preventDefault();        

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 
            if (result.isConfirmed) {

                try {
                    await axios.patch(`https://api.seguroll-asistencias.com/profiles/${id}`, {
                        poliza: poliza,
                        nombre: nombre,
                        apellido_paterno: apellido_paterno,
                        apellido_materno: apellido_materno,
                        empresa_user: 0,
                        fecha_nacimiento: '1901-01-01',
                        email: email,
                        password: password,
                        confPassword: confiPassword
                    });
                    Swal.fire("¡Éxito!", "La actualización se realizó con éxito", "success");
                    //navigate("/")
                } catch (error) {
                    if(error.response){
                        setMsg(error.response.data.msg)
                    }
                }

            }
        });        
    }

    return (
        <div className='mb-5'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Perfil</h1>
                        <h2 className='subtitle'>Editar perfil</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_edi_user} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>

                <Container style={{ maxHeight: "50%"}}>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={8} lg={10} xs={12}>
                            <Card className="shadow">                            
                                <Card.Body>
                                    <div className="mb-3 mt-0 md-4">                                    
                                        <p className=" mb-5 pt-0">*Todos los campos son obligatorios</p>
                                        <div className="mb-3">
                                            <Form onSubmit={updateUser}>
                                                {msg &&
                                                    <div className="alert alert-danger" role="alert">
                                                        {msg}
                                                    </div>
                                                }
                                                <Form.Group className="mb-3" controlId="formBasicPoliza">
                                                    <Form.Label className="text-center">
                                                    No. Poliza
                                                    </Form.Label>
                                                    <Form.Control value={poliza} onChange={(e) => setPoliza(e.target.value)} type="text" placeholder="Escribe tu numero de poliza" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                    Nombre
                                                    </Form.Label>
                                                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Escribe tu nombre" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoP">
                                                    <Form.Label className="text-center">
                                                    Apellido paterno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_paterno} onChange={(e) => setApellidoPaterno(e.target.value)} type="text" placeholder="Escribe tu apellido paterno" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicApellidoM">
                                                    <Form.Label className="text-center">
                                                    Apellido materno
                                                    </Form.Label>
                                                    <Form.Control value={apellido_materno} onChange={(e) => setApellidoMaterno(e.target.value)} type="text" placeholder="Escribe tu apellido materno" />
                                                </Form.Group>                                            

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                    Correo Electronico
                                                    </Form.Label>
                                                    <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Escribe tu correo electronico" />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicPassword"
                                                >
                                                    <Form.Label>Contraseña</Form.Label>
                                                    <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="******" />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicConfigPassword"
                                                >
                                                    <Form.Label>Confirma tu contraseña</Form.Label>
                                                    <Form.Control onChange={(e) => setConfiPassword(e.target.value)} type="password" placeholder="******" />
                                                </Form.Group>

                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Actualizar
                                                            </Button>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>

        </div>
    )
}

export default FromProfileUser