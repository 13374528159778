import React, {useState, useEffect, useMemo} from "react";
import DataTable from './DataTables';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import icono_usuario from "../img/icono_usuario3.png"
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import './table.css'

import icono_img from "../img/foto.png"
import icono_video from "../img/play.png"
import icono_pdf from "../img/pdf.png"
import icono_audio from "../img/audio.png"
import icono_archivos from "../img/carpeta.png"
import icono_adjunto from "../img/adjunto.png";

const ConversacionDetails = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});
    const [loading, setLoading] = useState(true);

    const [loadingEvidencias, setLoadingEvidencias] = useState(true);

    const [quejas, setQuejas] = useState([]);
    const [estatus, setEstatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');

    const [respuesta, setRespuesta] = useState("");
    const [msg, setMsg] = useState("");
    const {user} = useSelector((state) => state.auth);
    const {quejaId} = useParams();
    const [dataTable, setDataTable] = useState([]);

    const [loadingRespuesta, setLoadingRespuesta] = useState(false);

    const [idQueja, setIdQueja] = useState('');

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '13%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    useEffect(() => {        
        if (user) {
            getQuejasById();            
        }
        if(user && user.role === 'admin'){
            getEstatus();
        }
    }, [user]);

    // En tu función que maneja el cambio de estatus
    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const [evidencias, setEvidencias] = useState([]);

    const getQuejasById = async () => {
        try {
            setLoadingEvidencias(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/quejas/${quejaId}`);
            const orderedResponses = response.data.respuestas.sort((a, b) => a.id - b.id);
            response.data.respuestas = orderedResponses;

            console.log(orderedResponses)

            setSelectedStatus(response.data.statusId)
            setIdQueja(response.data.id);
            setQuejas(response.data);

            setEvidencias(response.data.evidencias)
            setDataTable(response.data.evidencias)

            setLoadingEvidencias(false); // Indicar que se están cargando los datos
        } catch (error) {            
            console.log(error.message);
        }
    }

    const getEstatus = async () => {
        try {
            setLoading(true); // Indicar que se están cargando los datos
            const response = await axios.get(`https://api.seguroll-asistencias.com/estatus`);            
            setEstatus(response.data);
            setLoading(false); // Indicar que se están cargando los datos
        } catch (error) {            
            console.log(error.message);
        }
    }

    const handleRespuestaChange = (event) => {
        setRespuesta(event.target.value);
    };

    const [adjuntos, setAdjuntos] = useState([]);

    // Función para manejar el cambio en el campo de archivo adjunto
    const handleAdjuntoChange = (e) => {
        const nuevosAdjuntos = [...adjuntos];
        nuevosAdjuntos.push(e.target.files[0]);
        setAdjuntos(nuevosAdjuntos);
    };
    
    // Función para eliminar un archivo adjunto
    const eliminarAdjunto = (indice) => {
        const nuevosAdjuntos = [...adjuntos];
        nuevosAdjuntos.splice(indice, 1);
        setAdjuntos(nuevosAdjuntos);
    };

    const enviarRespuesta = async (e) => {

        e.preventDefault();

        setLoadingRespuesta(true)

        const idUser = user.userId

        if(selectedStatus === ''){
            setMsg('Debes seleccionar un estatus.')
            return
        }

        const formData = new FormData();

        for (let i = 0; i < adjuntos.length; i++) {
            formData.append('files', adjuntos[i]);
        }

        formData.append('quejaId', idQueja);
        formData.append('userId', idUser);
        formData.append('selectedStatus', selectedStatus);
        formData.append('responseText', respuesta);

        try {
            const response = await axios.post(`https://api.seguroll-asistencias.com/respuestas`, formData)
            // Después de enviar la respuesta, actualiza el estado local "queja"

            const newRespuestaId = response.data.id;
/*
            setQuejas((prevQuejas) => {
                const newRespuesta = {
                    id: newRespuestaId, // Usar el ID de la respuesta recién creada
                    responseText: respuesta,
                    usuario: { role: user.role }
                };
            
                const updatedQuejas = {
                    ...prevQuejas,
                    respuestas: [...prevQuejas.respuestas, newRespuesta]
                };
            
                return updatedQuejas;
            });
*/
            getQuejasById();
            setLoadingRespuesta(false);
            setRespuesta(""); // Limpiar el campo de respuesta
        } catch (error) {
            setLoadingRespuesta(false);
            console.log(error.message);
        }
    };

    const columns = useMemo(
        () => {
            const baseColumns = [
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Nombre del archivo</div>
                    ),
                    accessor: 'userName',
                    Cell: ({ row }) => (
                        <div className="file-container">
                            <div className="file-icon">
                                {getFileIcon(row.original.fileName)}
                            </div>
                            <div className="file-name">
                                {row.original.userName}
                            </div>
                        </div>
                    )
                },
                {
                    Header: () => (
                        <div
                        style={{
                            textAlign:"center"
                        }}
                        >Descripción</div>
                    ),
                    accessor: 'description',
                }
            ];

            if (user) {
                if (user.role === "admin") {
                    baseColumns.push({
                        Header: () => (
                            <div
                                style={{
                                    textAlign: "center"
                                }}
                            >Acciones</div>
                        ),
                        accessor: 'actions',
                        Cell: ({ row }) => (
                            <div>
                                <a href={row.original.downloadUrl} className="btn btn-primary">
                                    Descargar
                                </a>
                            </div>
                        ),
                    });
                } else {
                    baseColumns.push({
                        Header: () => (
                            <div
                                style={{
                                    textAlign: "center"
                                }}
                            >Acciones</div>
                        ),
                        accessor: 'actions',
                        Cell: ({ row }) => (                            
                            <div>
                                <a href={row.original.downloadUrl} className="btn btn-primary">
                                    Descargar
                                </a>
                            </div>
                        ),
                    });
                }
            }
        
          return baseColumns;
        },
        [user]
    );

    const downloadMedia = async (uuid) => {
        try {
            setLoading(true);

            const response = await axios.get(`https://api.seguroll-asistencias.com/quejas/download/${uuid}`, {
                responseType: 'blob', // Indicar que la respuesta es un archivo binario
            });

            const filename = getFilenameFromResponseHeaders(response);

            const contentType = response.headers['content-type'];

            // Crear un enlace temporal y simular un clic para iniciar la descarga
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
            link.download = filename;
            link.click();

            setLoading(false);
        } catch (error) {
            console.error(error.message);
        }
    }

    function getFilenameFromResponseHeaders(response) {
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename=["']?([^"']+)/);
            if (filenameMatch && filenameMatch.length > 1) {
                return filenameMatch[1];
            }
        }
        return 'archivo-descargado'; // Valor por defecto en caso de no encontrar el nombre del archivo
    }

    function getFileIcon(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        const iconMap = {
            pdf: <img src={icono_pdf} alt="PDF" style={{ width: '20px', height: '20px' }}/>,
            jpg: <img src={icono_img} alt="JPG" style={{ width: '20px', height: '20px' }}/>,
            png: <img src={icono_img} alt="PNG" style={{ width: '20px', height: '20px' }}/>,
            docx: <img src={icono_archivos} alt="DOCX" style={{ width: '20px', height: '20px' }}/>,
            mp4: <img src={icono_video} alt="MP4" style={{ width: '20px', height: '20px' }}/>, // Icono para videos MP4
            avi: <img src={icono_video} alt="AVI" style={{ width: '20px', height: '20px' }}/>, // Icono para videos AVI
            mp3: <img src={icono_audio} alt="MP3" style={{ width: '20px', height: '20px' }}/>, // Icono para archivos de audio MP3
            wav: <img src={icono_audio} alt="WAV" style={{ width: '20px', height: '20px' }}/>, // Icono para archivos de audio WAV
            // ... Otros tipos de archivo y sus iconos ...
        };
    
        return iconMap[extension] || <img src={icono_adjunto} alt="Archivo" style={{ width: '20px', height: '20px' }}/>;
    }

    return (
        <div className='container-fluid'>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Conversación</h1>
                        <h5 className='subtitle'>Historial de la conversación</h5>
                    </div>
                    <div className='col text-end'>
                        <img src={icono_usuario} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='conversacion'>
                {/* Mostrar la descripción original de la queja */}
                <div className="card">
                    <div className="card-header">
                        {user && quejas ? (
                            <div>
                                <p>
                                    Usuario que reporta: {quejas.usuario && `${quejas.usuario.nombre} ${quejas.usuario.apellido_paterno} ${quejas.usuario.apellido_materno}`}
                                </p>                                
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="card-body">                                          
                        {user && quejas ? (
                            <div>
                                <h5>Datos del quejoso:</h5>
                                <p>
                                    <strong>Nombre completo:</strong> {quejas.fullName && `${quejas.fullName}`}
                                </p>
                                <p>
                                    <strong>Telefono:</strong> {quejas.fullName && `${quejas.telefono}`}
                                </p>
                                <p>
                                    <strong>Correo electronico:</strong> {quejas.fullName && `${quejas.email}`}
                                </p>
                                <div className="descripcion-container">
                                    <p>
                                        <strong>Queja:</strong> {quejas.descripcion}
                                    </p>
                                </div>                                
                                <p>
                                    <strong>Empresa:</strong> {quejas.empresa ? quejas.empresa.nombre : 'Sin empresa registrada'}
                                </p>
                                <p>
                                    <strong>Sucursal:</strong> {quejas.sucursale ? quejas.sucursale.nombre : 'Sin sucursal'}
                                </p>
                                <p>
                                    <strong>Fecha del suceso:</strong> {quejas.fechaIncidente ? quejas.fechaIncidente : 'Sin fecha de incidente'}
                                </p>
                                <p>
                                    <strong>Hora aproximada:</strong> {quejas.horaIncidente ? quejas.horaIncidente : 'Sin hora aproximada'}
                                </p>
                            </div>
                        ):(
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className='card mt-2 shadow bg-white p-3 mb-3 rounded'>
                <h5>Evidencia adjunta</h5>
                <div className='card mt-3 shadow p-3 mb-5 bg-body rounded'>                    
                    {loadingEvidencias ? (
                        <p>No hay evidencia adjunta...</p>
                    ) : (
                        <>
                            {dataTable.some((element) => element === undefined) ? (
                                <p>Ninguna evidencia adjunta</p>
                            ): (
                                <div>
                                    {dataTable.length > 0 ? <DataTable columns={columns} data={dataTable} /> : 'Sin evidencia adjunta' }
                                </div>                                
                            )}
                        </>
                    )}
                </div>
            </div>
            
            {/* Mostrar respuestas */}
            <div className='card mt-5 shadow p-3 mb-3 rounded' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <h5>Conversación:</h5>
                <div className='card-body'>
                    {quejas && quejas.respuestas && quejas.respuestas.map((respuesta, index) => (
                        <div
                            key={index}
                            className={`respuesta ${respuesta.usuario.role === 'user' ? 'usuario' : 'admin'}`}
                        >
                            {respuesta.usuario.role === 'admin' ? `Admin: ${respuesta.responseText}` : ''}
                            {respuesta.usuario.role === 'user' && ` ${respuesta.responseText}`}
                            {respuesta.files_respuestas &&
                            respuesta.files_respuestas.map((archivo, indexFile) => (
                                <div key={indexFile}>
                                    <a href={archivo.downloadUrl}>{archivo.userName}</a>                                    
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            {/* Campo para escribir una respuesta */}
            <div className='mb-15 mt-5'>
                <form onSubmit={enviarRespuesta}>
                    <textarea
                        className='form-control'
                        placeholder='Escribe tu respuesta aquí...'
                        value={respuesta}
                        onChange={handleRespuestaChange}
                    />

                    {/* Campo de archivo adjunto */}
                    <input
                        type='file'
                        className='form-control mt-2'
                        onChange={handleAdjuntoChange}
                    />
                    {/* Lista de archivos adjuntos */}
                    {adjuntos.length > 0 && (
                        <div className='mt-2'>
                        <h5>Archivos Adjuntos:</h5>
                        <ul>
                            {adjuntos.map((archivo, indice) => (
                            <li key={indice}>
                                {archivo.name}{" "}
                                <button
                                className="btn btn-danger btn-sm"
                                onClick={() => eliminarAdjunto(indice)}
                                >
                                Eliminar
                                </button>
                            </li>
                            ))}
                        </ul>
                        </div>
                    )}

                    {user && user.role === 'admin' && (
                        <>
                            {msg &&
                            <div className="alert alert-danger" role="alert">
                                {msg}
                            </div>
                            }
                        
                            <select className='form-control mt-2' value={selectedStatus} onChange={handleStatusChange}>
                                <option value=''>Selecciona un estatus</option>
                                {estatus.map((status) => (
                                    <option key={status.id} value={status.id}>
                                        {status.estatus}
                                    </option>
                                ))}
                            </select>
                        </>
                    )}
                    {loadingRespuesta ? 
                        <p>Enviando respuesta...</p>
                    : 
                        <button className='btn btn-primary mt-2' type="submit" style={{ marginBottom: '100px' }}>
                            Enviar Respuesta
                        </button>
                    }
                </form>
            </div>

        </div>
    )

}

export default ConversacionDetails