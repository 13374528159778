import React, {useState, useEffect} from 'react'
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';
import add_icon_empresa from "../img/icono_add_empresa.png"
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const FormAddEmpresa = () => {

    // Declarar imageStyle aquí, en el alcance del componente Navbar
    const [imageStyle, setImageStyle] = useState({});

    const applyImageStyles = () => {
        const smallScreen = window.matchMedia('(max-width: 576px)').matches;
        const mediumScreen = window.matchMedia('(min-width: 577px) and (max-width: 768px)').matches;
        const largeScreen = window.matchMedia('(min-width: 769px)').matches;

        if (smallScreen) {
        setImageStyle({ maxWidth: '75%' });
        } else if (mediumScreen) {
        setImageStyle({ maxWidth: '35%' });
        } else if (largeScreen) {
        setImageStyle({ maxWidth: '20%' });
        }
    };

    useEffect(() => {
        applyImageStyles();
        window.addEventListener('resize', applyImageStyles);
        return () => window.removeEventListener('resize', applyImageStyles);
    }, []);

    const [nombre, setNombre] = useState("");
    const [sucursales, setSucursales] = useState([]);
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const agregarSucursal = () => {
        setSucursales([...sucursales, '']);
    };

    const eliminarSucursal = (index) => {
        const nuevasSucursales = [...sucursales];
        nuevasSucursales.splice(index, 1);
        setSucursales(nuevasSucursales);
    };
    
    const handleChangeSucursal = (index, value) => {
        const nuevasSucursales = [...sucursales];
        nuevasSucursales[index] = value;
        setSucursales(nuevasSucursales);
    };

    const saveEmpresa = async(e) => {
        e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta por registrar una nueva empresa',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => { 

            if (result.isConfirmed) {

                try {
                    await axios.post('https://api.seguroll-asistencias.com/empresas', {
                        nombre: nombre,
                        sucursales: sucursales
                    })
                    navigate("/empresas");
                } catch (error) {
                    if(error.response){
                        setMsg(error.response.data.msg);
                    }
                }

            }

        });   
        
    }

    return (
        <div>

            <div className='card mt-5 shadow bg-dark text-light p-3 mb-3 rounded'>
                <div className='card-body'>
                    <div className='row'>
                    <div className='col'>
                        <h1 className='title'>Empresas</h1>
                        <h2 className='subtitle'>Agregar una empresa</h2>
                    </div>
                    <div className='col text-end'>
                        <img src={add_icon_empresa} style={imageStyle} alt="icono de usuarios" className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 shadow bg-white p-3 mb-3 rounded'>

                <Container style={{ maxHeight: "80%"}}>
                    <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Col md={6} lg={10} xs={12}>
                            <Card className="shadow">
                                <Card.Body className=''>
                                    <div className="mb-3 mt-0 md-4">                                    
                                        <p className=" mb-2 pt-0">*Todos los campos son obligatorios</p>
                                        <div className="mb-3">
                                            <Form onSubmit={saveEmpresa}>
                                                {msg &&
                                                <div className="alert alert-danger" role="alert">
                                                    {msg}
                                                </div>
                                                }    
                                                <Form.Group className="mb-3" controlId="formBasicNombre">
                                                    <Form.Label className="text-center">
                                                    Nombre
                                                    </Form.Label>
                                                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" placeholder="Nombre de la empresa" />
                                                </Form.Group>

                                                <button type="button" className='btn btn-secondary mb-3' onClick={agregarSucursal}>Agregar Sucursal</button>
                                                <div>
                                                    {sucursales.map((sucursal, index) => (
                                                    <div key={index}>
                                                        <label className='m-2' htmlFor={`sucursal-${index}`}>Sucursal {index + 1}:</label>
                                                        <input
                                                        type="text"
                                                        id={`sucursal-${index}`}
                                                        value={sucursal}
                                                        onChange={(e) => handleChangeSucursal(index, e.target.value)}
                                                        />
                                                        <button type="button" className="btn btn-light m-2" onClick={() => eliminarSucursal(index)}>Eliminar</button>
                                                    </div>
                                                    ))}
                                                </div>
                                                
                                                <div className="d-grid container">
                                                    <div className='row justify-content-center align-items-center'>
                                                        <div className='col text-center'>
                                                            <Button className='btn btn-dark' variant="primary" type="submit">
                                                                Registrar
                                                            </Button>
                                                        </div>                                                          
                                                    </div>                                                    
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
            
        </div>
    )
}

export default FormAddEmpresa