import React from 'react'
import StickyNavbar from '../components/Navbar'
//import Sidebar from '../components/Sidebar'

const Layout = ({children}) => {
  return (
    <React.Fragment>
        <StickyNavbar/>
        <div className="container-fluid mt-12" style={{ minHeight: "100vh", paddingTop: "70px" }}>
          <div className="bg-light"  style={{ maxWidth: "100%", minHeight: "100vh" }}>
            <main>{children}</main>
          </div>
          {/*
          <div className="row" style={{ maxWidth: "100%" }}>
            <div className="col-3 mr-0"><Sidebar/></div>
            
          </div>*/}
        </div>
    </React.Fragment>
  )
}

export default Layout