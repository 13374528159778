import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables} from 'chart.js';
Chart.register(...registerables);

const StackedColumnChart = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.seguroll-asistencias.com/dataGrafica');
        setChartData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {chartData && (
        <Bar
          data={{
            labels: chartData.labels,
            datasets: chartData.datasets,
          }}
          options={{
            responsive: true,
            plugins: {
                legend: {
                  display: false, // Esto oculta las leyendas
                },
            },
            scales: {
              x: {
                type: 'category',
                stacked: true,
                ticks: {
                  font: {
                    size: 7, // Tamaño de la letra en el eje X
                  },
                },
              },
              y: {
                stacked: true,
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default StackedColumnChart;
